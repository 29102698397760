import { AxiosInstanceType, TopcoAxiosInstance } from '../topcoAxiosInstance';

interface EpisodePurchaseType {
  comicId: number;
  episodeId: number;
  purchaseType?: string;
}

interface EpisodePurchaseTypeV2 {
  cToken: string;
  purchaseType?: string;
  isBingeWatching: number;
}

interface UnlockAllType {
  comicId: number;
  type: string;
}

interface GetViewerType {
  comicId: number;
  episodeId: number;
  viewerToken?: string;
  cToken?: string;
}

export interface EpisodeLikeType {
  comicId: number;
  episodeId: number;
}

export interface ViewerUserRequestType {
  comicId: number | null;
  episodeId: number | null;
  issueCategory: number;
  deviceOs?: number;
  email: string | null;
  subject: string;
  description: string;
  location: string;
}
interface ApiCheckViewerRequestType {
  location: string;
  action: string;
  purchaseType?: string;
  isCached?: boolean;
  cToken?: string;
}

interface LogAndStatRecommendType {
  toComicId: number;
  fromComicId: number;
  location: string;
}

interface UpdateBinge {
  isBingeWatching: number;
}

export interface ViewrLogRequestType {
  comicId: number;
  episodeId: number;
  scroll: number;
  time: number;
}

export interface ViewrMembershipLogRequestType {
  comicId: number;
  episodeId: number;
  scroll: number;
  time: number;
  logMembershipAdjustId: number;
}

export default class ApiEpisode extends TopcoAxiosInstance {
  private GET_EPISODE_DATA = '/api/v1/page/episode';

  private GET_VIEWER_DATA = '/api/v1/page/viewer';

  private IS_USABLE_EPISODE = '/check/isUsableEpisode';

  // todo: test version
  private IS_USABLE_EPISODE_INTERGRATION_VERSION = '/api/v2/viewer';

  private PURCHASE_EPISODE = '/api/v1/episode/purchase';

  private PURCHASE_EPISODEV2 = '/api/v2/episode/purchase';

  private UNLOCK_ALL_EPISODE = '/api/v1/unlockAll/purchase';

  private SET_LIKE = '/api/v1/episode/setLike';

  private DEL_LIKE = '/api/v1/episode/delLike';

  private VIEWER_USER_REQUEST = '/api/v1/userRequest';

  private LOG_AND_RECOMMEND = '/statistics/logAndStatRecommendListClick';

  private SET_MEMBERSHIP_EXPERIENCE = '/users/setMembershipExperience';

  private UPDATE_BINGE = '/users/setStatusBingeWatching';

  private VIEWER_LOG = '/statistics/viewer/log';

  private VIEWER_MEMBERSHIP_CALC_LOG =
    '/statistics/viewer/membership/calculate/log';

  constructor(initValue: AxiosInstanceType) {
    super(initValue);
  }

  /**
   * 회차리스트 api
   * @param comic : comic ID
   * @param is17 : 17금 파트너코드를 사용하고 있는지
   * @param isAlreadyMature : 사전인증 상태
   * @returns :
   *  comic: 만화정보,
   *  episodeList: 회차리스트 정보
   */
  public getEpisodeData = (comic: number) => {
    return this.requst<null>({
      url: this.GET_EPISODE_DATA,
      params: { comicId: comic },
    });
  };

  /**
   * 회차 api
   * @param comic
   * @param episode
   * @param cToken
   * @returns
   */
  public getViewerData = (
    comic: number,
    episode: number,
    viewerToken?: string,
    cToken?: string
  ) => {
    const data: GetViewerType = {
      comicId: comic,
      episodeId: episode,
      viewerToken: viewerToken ?? '',
      cToken: cToken ?? '',
    };

    return this.requst<GetViewerType>({
      method: 'post',
      url: this.GET_VIEWER_DATA,
      data,
    });
  };

  /**
   * 회차 클릭시 다음 액션에 대한 체크 api
   * @param comicId
   * @param episodeId
   * @location 호출 위치
   * @action 호출시 동작
   */
  public apiIsUsableEpisode = (
    comicId: number,
    episodeId: number,
    location: string,
    action: string,
    purchaseType: string = ''
  ) => {
    const params: any = {
      comicId,
      episodeId,
      location,
      action,
      purchaseType,
    };

    if (purchaseType === '') {
      delete params.purchaseType;
    }

    return this.requst<null>({
      url: this.IS_USABLE_EPISODE,
      params,
    });
  };

  /**
   * apiCheckViewer (isUsableEpisode + viewer 결합 api)
   * @param comicId
   * @param episodeId
   * @param location
   * @param action
   * @param purchaseType
   * @param isCached content data 사용 유무
   * @param cToken
   * @returns
   */
  // public apiIsUsableEpisodeTestVersion = (
  public apiCheckViewer = (
    comicId: number,
    episodeId: number,
    params: ApiCheckViewerRequestType
  ) => {
    const {
      location,
      action,
      purchaseType = '',
      isCached = false,
      cToken = '',
    } = params;
    const data: ApiCheckViewerRequestType = {
      location,
      action,
      purchaseType,
      isCached,
      cToken,
    };

    if (purchaseType === '') {
      delete data.purchaseType;
    }

    return this.requst<ApiCheckViewerRequestType>({
      method: 'post',
      url: `${this.IS_USABLE_EPISODE_INTERGRATION_VERSION}/${comicId}/${episodeId}`,
      data,
    });
  };

  /**
   * 코인 소진 처리 api (성공시 뷰어로 진입)
   * @param comicId
   * @param episodeId
   */
  public purchaseEpisode = (
    comicId: number,
    episodeId: number,
    purchaseType: string = ''
  ) => {
    const data: EpisodePurchaseType = {
      comicId,
      episodeId,
      purchaseType,
    };

    if (purchaseType === '') {
      delete data.purchaseType;
    }

    return this.requst<EpisodePurchaseType>({
      method: 'post',
      url: this.PURCHASE_EPISODE,
      data,
    });
  };

  /**
   * 코인 소진 처리 api V2 (성공시 content get)
   * @param comicId
   * @param episodeId
   * @param params
   */
  public purchaseEpisodeV2 = (
    comicId: number,
    episodeId: number,
    params: EpisodePurchaseTypeV2
  ) => {
    const data: EpisodePurchaseTypeV2 = {
      purchaseType: params.purchaseType ?? '',
      cToken: params.cToken,
      isBingeWatching: params.isBingeWatching ?? 0,
    };

    if (data.purchaseType === '') {
      delete data.purchaseType;
    }

    return this.requst<EpisodePurchaseTypeV2>({
      method: 'post',
      url: `${this.PURCHASE_EPISODEV2}/${comicId}/${episodeId}`,
      data,
    });
  };

  /**
   * 전체 구매 처리 api
   * @param comicId
   * @param type : coin(유료만) / all(무료포함)
   */
  public unlockAllEpisode = (comicId: number, type: string) => {
    const data: UnlockAllType = {
      comicId,
      type,
    };

    return this.requst<UnlockAllType>({
      method: 'post',
      url: this.UNLOCK_ALL_EPISODE,
      data,
    });
  };

  /**
   * 회차리스트 좋아요 api
   * @param comicId
   * @param episodeId
   * @param isLike
   * @returns
   */
  public updateEpisodeLike = (
    comicId: number,
    episodeId: number,
    isLike: boolean
  ) => {
    const data: EpisodeLikeType = {
      comicId,
      episodeId,
    };

    return this.requst<EpisodeLikeType>({
      method: 'post',
      url: isLike ? this.SET_LIKE : this.DEL_LIKE,
      data,
    });
  };

  /**
   * 뷰어 리퀘스트 api
   * @param comicId : comic ID
   * @param episodeId : 17금 파트너코드를 사용하고 있는지
   * @param issueCategory : 이슈 타입
   * @param deviceOs : 디바이스 타입
   * @param email : 이메일폼
   * @param subject : 제목폼
   * @param description : 내용폼
   */
  public viewerUserRequest = (request: ViewerUserRequestType) => {
    return this.requst<ViewerUserRequestType>({
      method: 'post',
      url: `${this.VIEWER_USER_REQUEST}/${request.location}/set`,
      data: request,
    });
  };

  public viewerStayTimeAndScrollPositionLogCheck = (
    request: ViewrLogRequestType
  ) => {
    return this.requst<ViewrLogRequestType>({
      method: 'post',
      url: this.VIEWER_LOG,
      data: request,
    });
  };

  /**
   * 멤버쉽 정산용 체류시간 및 스크롤 % 체크 log
   * @param request
   * @returns
   */
  public viewerMembershipCalcLog = (request: ViewrMembershipLogRequestType) => {
    return this.requst<ViewrMembershipLogRequestType>({
      method: 'post',
      url: this.VIEWER_MEMBERSHIP_CALC_LOG,
      data: request,
    });
  };

  public recommendListLogAndStat = (request: LogAndStatRecommendType) => {
    return this.requst<LogAndStatRecommendType>({
      method: 'post',
      url: this.LOG_AND_RECOMMEND,
      data: request,
    });
  };

  public setMembershipExperience = () => {
    return this.requst<any>({
      method: 'post',
      url: this.SET_MEMBERSHIP_EXPERIENCE,
    });
  };

  /**
   * 정주행 모드 api
   * @param isBingeWatching
   * @constructor
   */
  public setBingeWatching = (isBingeWatching: number) => {
    const data: UpdateBinge = {
      isBingeWatching,
    };

    return this.requst<UpdateBinge>({
      method: 'post',
      url: this.UPDATE_BINGE,
      data,
    });
  };
}
