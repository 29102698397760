import { AxiosInstanceType, TopcoAxiosInstance } from "../topcoAxiosInstance";

interface RequestPutSmsAuthKey {
  phoneNumber: string;
}

interface RequestValidationAuthKey {
  phoneNumber: string;
  authKey: number;
}

export default class ApiSms extends TopcoAxiosInstance {
  private PUT_SMS_AUTH_KEY = "/auth/smsAuthKeySend";

  private VALIDATION_AUTH_KEY = "/auth/smsAuthKeyValidation";

  private AUTH_KEY_STAT_LAYER_COUNT = "/auth/smsAuthKeyStatLayerCount";

  private AUTH_KEY_CONFIRM = "/auth/smsAuthKeyConfirm";

  constructor(initValue: AxiosInstanceType) {
    super(initValue);
  }

  /**
   * SMS 인증번호 전송 요청
   * @param phoneNumber
   * @returns
   */
  public sendAuthKey = (phoneNumber: string) => {
    const data: RequestPutSmsAuthKey = {
      phoneNumber,
    };

    return this.requst<RequestPutSmsAuthKey>({
      method: "post",
      url: this.PUT_SMS_AUTH_KEY,
      data,
    });
  };

  /**
   * SMS 인증번호 검증
   * @param phoneNumber
   * @param authKey
   * @returns
   */
  public validationSmsAuthKey = (phoneNumber: string, authKey: number) => {
    const data: RequestValidationAuthKey = {
      phoneNumber,
      authKey,
    };

    return this.requst<RequestValidationAuthKey>({
      method: "post",
      url: this.VALIDATION_AUTH_KEY,
      data,
    });
  };

  /**
   * SMS 인증 번호 확인 및 등록처리
   * @param phoneNumber
   * @param authKey
   * @returns
   */
  public confirmAuthKey = (phoneNumber: string, authKey: number) => {
    const data: RequestValidationAuthKey = {
      phoneNumber,
      authKey,
    };

    return this.requst<RequestValidationAuthKey>({
      method: "post",
      url: this.AUTH_KEY_CONFIRM,
      data,
    });
  };

  /**
   * SMS Layer - CountUp
   * @returns
   */
  public LayerCount = () => {
    return this.requst<null>({
      method: "post",
      url: this.AUTH_KEY_STAT_LAYER_COUNT,
    });
  };
}
