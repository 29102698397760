import { AxiosInstanceType, TopcoAxiosInstance } from "../topcoAxiosInstance";

export default class ApiCrazyBanner extends TopcoAxiosInstance {
  private GET_CRAZY_BANNER = "/api/v1/event/crazyBanner";

  constructor(initValue: AxiosInstanceType) {
    super(initValue);
  }

  /**
   * banner
   * @returns
   */
  public getCrazyBanner = () => {
    return this.requst<null>({
      method: "post",
      url: this.GET_CRAZY_BANNER,
    });
  };
}
