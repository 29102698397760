import { AxiosInstanceType, TopcoAxiosInstance } from '../topcoAxiosInstance';

export interface RequestCouponNumber {
  couponNumber: string;
}

export interface RequestCouponName {
  couponName: string;
}

export default class ApiCoupon extends TopcoAxiosInstance {
  private SET_COUPON = '/api/v1/coupon/setCoupon';
  private ONE_TIME_COUPON = '/api/v1/coupon';
  private REGISTER_COUPON = '/api/v2/coupon/register';

  constructor(initValue: AxiosInstanceType) {
    super(initValue);
  }

  /**
   * 쿠폰 사용 통합 api
   * @param couponName
   * @returns
   */
  public registerCoupon = (couponName: string) => {
    return this.requst<RequestCouponName>({
      method: 'post',
      url: this.REGISTER_COUPON,
      data: {
        couponName,
      },
    });
  };

  /**
   * @param couponNumber
   * @returns
   */
  public setCoupon = (couponNumber: string) => {
    return this.requst<RequestCouponNumber>({
      method: 'post',
      url: this.SET_COUPON,
      data: {
        couponNumber,
      },
    });
  };

  /**
   * 일회성 쿠폰에 대한 등록 api
   * @param coupon
   * @returns
   */
  public oneTimeCoupon = (coupon: string) => {
    return this.requst<null>({
      method: 'post',
      url: `${this.ONE_TIME_COUPON}/${coupon}`,
    });
  };
}
