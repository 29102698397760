import { PaymentContext, ProductItemType } from '../context';

import AppManager from '@src/lib/app/AppManager';
import { PaymentWindowModeType } from '../data/_constants';
import { UserDefault } from '@src/lib';
import { PaymentPrepareRequest } from '@src/lib/commonLib/api/client/V1/ApiPayment';

// TODO : 자동충전 TEST
export namespace PaymentWallRefillPurchase {
  export const paymentWallRefillPurchase = (
    context: PaymentContext,
    payMethod: string,
    selectProductItem: ProductItemType | null,
  ) => {
    const { selectCoupon, loginInfo, windowMode, isMobile } = context;

    if (!loginInfo) return;
    // if (!loginInfo || !selectProductItem) return;
    console.log('ee');
    const { token, loginId, provider, userId } = loginInfo;

    const selectObj: PaymentPrepareRequest.PaymentPrepareType = {
      accessToken: token,
      productId: Number(selectProductItem?.productId),
      pg: 'paymentwall',
      viewType: windowMode ?? PaymentWindowModeType.LARGE,
      latestLocation: `${window.location.pathname}`,
      CouponItem: selectCoupon,
      payMethod: payMethod,
    };

    // 상품정보 저장
    UserDefault.payment.setBuckets(selectObj);

    if (AppManager.getInstance().isApp()) {
      const encodeSelectItem = JSON.stringify(selectObj)
        .unicode()
        .encodeString('test');

      const rUri = AppManager.getInstance().currentPage.getter();
      AppManager.getInstance().action.paymentPopup(
        `accessToken=${token}&provider=${provider}&userId=${userId}&loginId=${loginId}&selectItem=${encodeSelectItem}&redirectUri=${rUri}&pgString=paymentWallRefill`,
      );
    } else {
      const windowSize =
        payMethod === 'gPay'
          ? `width=980, height=500`
          : 'width=980, height=714';
      window.open('/popup/paymentWallRefill', 'payViewer', `${windowSize}`);
    }
  };
}
