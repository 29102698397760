import { AxiosInstanceType, TopcoAxiosInstance } from "../topcoAxiosInstance";

export interface ArtworksIdType {
  artworksId: number;
}
export interface ArtworksShareType extends ArtworksIdType {
  channel: string;
}
export interface ArtworksInFlowedType extends ArtworksShareType {
  refererUrl: string;
}

export default class ApiArtworks extends TopcoAxiosInstance {
  private GetLikeList = "api/v1/artworks/likelist";

  private ToggleLike = "api/v1/artworks/like";

  private UpdateUserData = "api/v1/artworks/getOne";

  private ShareArtworks = "api/v1/artworks/shared";

  private InflowedArtworks = "api/v1/artworks/inflowed";

  constructor(initValue: AxiosInstanceType) {
    super(initValue);
  }

  public getLikeList = () => {
    return this.requst<null>({
      method: "post",
      url: this.GetLikeList,
    });
  };

  public toggleLike = (request: ArtworksIdType) => {
    return this.requst<ArtworksIdType>({
      method: "post",
      url: this.ToggleLike,
      data: request,
    });
  };

  public updateUserData = (request: ArtworksIdType) => {
    return this.requst<ArtworksIdType>({
      method: "post",
      url: this.UpdateUserData,
      data: request,
    });
  };

  /**
   * Artworks 공유하기 기능 로그 쌓는 API
   * @param request artworksId, channel
   * @returns
   */
  public shareArtworks = (request: ArtworksShareType) => {
    return this.requst<ArtworksShareType>({
      method: "post",
      url: this.ShareArtworks,

      data: request,
    });
  };

  /**
   * Artworks 공유하기를 통해 유입된 referer url 로그 쌓는 API
   * @param request refererUrl
   * @returns
   */
  public inflowedArtworks = (request: ArtworksInFlowedType) => {
    return this.requst<ArtworksInFlowedType>({
      method: "post",
      url: this.InflowedArtworks,
      data: request,
    });
  };
}
