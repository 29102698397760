import {
  ApiNetworkCallbackType,
  AxiosInstanceType,
  TopcoAxiosInstance,
} from "../topcoAxiosInstance";

/**
 * 사용자 세션(로그아웃) 관리
 */
export default class ApiUserSession extends TopcoAxiosInstance {
  constructor(initValue: AxiosInstanceType) {
    super(initValue);
  }

  public addUserSessionController(props: ApiNetworkCallbackType) {
    this.defaultInterceptor(props);
  }
}
