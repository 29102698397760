import { useCallback, useEffect } from 'react';

import Cookies from 'js-cookie';
import { UserSession } from '@src/lib';
import { UserSubscribeState } from '@src/atom/user';
import _ from 'lodash';
import dayjs from 'dayjs';
import { fetchMature } from '@src/atom/fetchMature';
import { useRouter } from 'next/router';
import { useSetRecoilState } from 'recoil';
import useUserCoin from '@src/hooks/useUserCoin';
import { VisitorUtils } from '@src/lib/utils/VisitorUtils';

/**
 * useAppContext에 있던 내용
 * 모든페이지에서 새로고침될때 동작하기 위함.
 * @returns
 */
const UserCoinResponse = () => {
  const router = useRouter();
  const { getUserData, refreshSnb, menuUpdate } = useUserCoin();
  const setFetchMature = useSetRecoilState(fetchMature);
  const setUserSubscribeState = useSetRecoilState(UserSubscribeState);

  // const [extendedResult, updateExtendedResult] = useState(false);
  // const { isLoading, error, data, getData } = useVisitorData(
  //   { extendedResult: false },
  //   { immediate: false },
  // );

  // useEffect(() => {
  //   if (extendedResult) {
  //     getData();
  //   }
  // }, [extendedResult]);

  // useEffect(() => {
  //   if (!data) return;
  //   // if (!data.visitorFound) return;

  //   UserSession.setFingerPrintId(data.visitorId);
  //   updateExtendedResult(false);
  // }, [data]);

  useEffect(() => {
    getFingerKey();
    refreshSnb();
    menuUpdate();

    const { token, mature } = UserSession.getUserInfo();

    setFetchMature({ isFetchMature: mature === 1 });
    if (!token) {
      setUserSubscribeState(null);
      return;
    }

    const isPathCheck = (path: string) => {
      const list = [
        '/comic/[comicId]/[episodeId]',
        '/content/[comicId]/[episodeId]',
        '/comic/[comicId]',
        '/content/[comicId]',
        '/gift',
        '/mypage',
        '/cb/[pg]',
        '/complete/[payment]',
        '/coupon',
        '/genres',
        '/payment',
      ];

      return list.includes(path);
    };

    const pathname = router.pathname;

    if (isPathCheck(pathname)) {
      getUserData();
      return;
    }

    const { refreshAt } = UserSession.getCoinInfo();

    if (refreshAt) {
      const now = dayjs().utc();
      const refreshDate = dayjs(refreshAt).utc();
      // refreshAt 시간과 현재 시간을 utc로 비교하여 갱신 유무 결정
      if (now.diff(refreshDate) >= 0) {
        getUserData();
        return;
      }
    }

    /**
     * 해당 쿠키값은 coin 정보 획득시 생성 되며 ttl이 종료되어 삭제 되었을 경우 다시 요청하는 용도로 사용
     */
    const refresh = Cookies.get('coinRefresh');
    if (!refresh) {
      getUserData();
      return;
    }

    setUserSubscribeState(UserSession.getCoinInfo().userSubscribe);
  }, [router.pathname]);

  const getFingerKey = useCallback(() => {
    if (!process.env.THEROK_KEY && UserSession.getUserInfo().token) {
      VisitorUtils.validateVisitorId()
        .then(res => {
          if (res) {
            UserSession.isRefreshVisitorId.setter(res);
            UserSession.isNeedValidateVisitorId.remove();
          } else {
            VisitorUtils.fetchVisitorId(true);
          }
        })
        .catch();
    }

    // const fId = UserSession.getFingerPrintId();
    // if (fId.length === 0) {
    //   updateExtendedResult(true);
    // }
  }, [router.pathname]);

  return <></>;
};

export default UserCoinResponse;
