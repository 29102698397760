import { useEffect, useRef, useState } from 'react';

import AppManager from '@src/lib/app/AppManager';
import { AuthController } from '@src/auth/AuthController';
import { Event } from '@src/lib/commonLib/gtm/Gtm';
import { GlobalGtm } from '@src/lib/commonLib/gtm/global';
import GoogleLoginButton from './GoogleLogin';
import { SnsBtnTypeCode } from './constants/SnsBtnTypeCode';
import { SnsComment } from '.';
import { TopcoPartner } from '@src/lib/partner';
import { UserSession } from '@src/lib';
import { VisitorUtils } from '@src/lib/utils/VisitorUtils';
import cn from 'clsx';
import { openLoginErrorNotificationModal } from '@src/view/modal/loginErrorNotification/LoginErrorNotificationModal';
import useLogin from '@src/hooks/useLogin';
import { useRouter } from 'next/router';

interface PropTypes {
  type: SnsBtnTypeCode; // login: login, join: join, snbMenu: default 적용 구분값
}

/**
 * snsLoginComponent
 * isLoginError 로그인 에러 대비한 key값 아이템별 true일때 로그인 에러 안내 모달 노출
 * 대상, apple, google, twitter, email
 * @param props
 * @returns
 */
const SnsButtons = (props: PropTypes) => {
  const { type } = props;
  const [checkSnsType, setCheckSnsType] = useState('');
  const [isApp, setIsApp] = useState(false);
  const [googleClick, setGoogleClick] = useState(false); // true면 구글 버튼 클릭되지 않도록 (APP)
  const router = useRouter();
  const googleLoginRef = useRef(null);
  useEffect(() => {
    setCheckSnsType(UserSession.getLastLoginProvider());
    setIsApp(AppManager.getInstance().isApp());
  }, [router]);

  const sendGtm = () => {
    if (type === SnsBtnTypeCode.Login) return; // 로그인 버튼에선 동작 X
    // GTM - 가입 버튼 클릭
    try {
      GlobalGtm.getInstance().setter({
        event: Event.JOIN_BUTTON_CLICK,
        pCode: TopcoPartner.getter(),
      });
    } catch {
      console.error('gtm');
    }
  };

  const { snsLoginApi } = useLogin();
  const snsItems = [
    {
      name: 'google',
      isLoginError: false,
      onClick: async (e: any) => {
        // 앱에서만 사용중
        e.preventDefault();
        e.stopPropagation();
        sendGtm();
        if (isApp) {
          if (googleClick) return; // 1초 이내에 클릭을 한 적이 있으면 리턴
          setGoogleClick(true);
          // 클릭 후 1초 동안 block 처리
          setTimeout(() => {
            setGoogleClick(false);
          }, 1000);
          AppManager.getInstance().currentPage.setter();
          const visitorId = await VisitorUtils.fetchVisitorId();
          AppManager.getInstance().action.snsLogin(
            'google',
            `visitorId=${visitorId}&preAuthToken=${UserSession.getPreAuthToken()}&isAlreadyMature=${0}`,
          );
          return;
        }
      },
    },
    {
      name: 'apple',
      isLoginError: false,
      onClick: async (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        sendGtm();
        const appManager = AppManager.getInstance();
        const visitorId = await VisitorUtils.fetchVisitorId();
        // ios store app일때만 app에서 처리
        if (appManager.isIosApp() && appManager.isStoreApp()) {
          appManager.action.snsLogin(
            'apple',
            `visitorId=${visitorId}&preAuthToken=${UserSession.getPreAuthToken()}&isAlreadyMature=${0}`,
          );
          return;
        }
        AuthController.appleLoginIn(uid => {
          if (uid) snsLoginApi('apple', { uid });
        });
      },
    },
    {
      name: 'twitter',
      isLoginError: false,
      onClick: async (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        sendGtm();
        const visitorId = await VisitorUtils.fetchVisitorId();
        if (isApp) {
          AppManager.getInstance().currentPage.setter();
          AppManager.getInstance().action.snsLogin(
            'twitter',
            `visitorId=${visitorId}&preAuthToken=${UserSession.getPreAuthToken()}&isAlreadyMature=${0}`,
          );
          return;
        }
        AuthController.twitterLogin(
          `${process.env.REACT_APP_PUBLIC_URL}/auth/twitter/`,
        );
      },
    },
  ];

  return (
    <div
      className={cn('snsLogin flex w-full flex-wrap', {
        ['max-w-[220px] mx-auto justify-between']:
          type !== SnsBtnTypeCode.Default,
        ['justify-center']: type === SnsBtnTypeCode.Default,
      })}
    >
      {snsItems.map((item, index) => {
        // 마케팅 트리거를 위해 로그인만 이름 변경
        const imgPath = `/images/sns/light/ico_${item.name}_black${
          type === SnsBtnTypeCode.Login ? '_login' : ''
        }.png`;

        return (
          <div
            key={`sns-item-${index}`}
            className={cn(``, {
              ['flex-1']: type !== SnsBtnTypeCode.Default,
              ['mx-[4%] mb-[3%]']: type === SnsBtnTypeCode.Default,
            })}
          >
            {item.name === 'google' && !isApp ? (
              <button
                id={`${item.name}-button`}
                type="button"
                className={cn(
                  'relative w-[42px] h-[42px] m-auto aspect-[1/1] block',
                  {
                    ['opacity-[0.2]']:
                      checkSnsType !== item.name &&
                      type === SnsBtnTypeCode.Login,
                  },
                )}
              >
                <GoogleLoginButton src={imgPath} alt={item.name} />
              </button>
            ) : (
              <button
                id={`${item.name}-button`}
                type="button"
                className={cn('w-[42px] h-[42px] m-auto aspect-[1/1] block', {
                  ['opacity-[0.2]']:
                    checkSnsType !== item.name && type === SnsBtnTypeCode.Login,
                })}
                title={item.name}
                onClick={e => {
                  if (item.isLoginError) {
                    openLoginErrorNotificationModal({
                      provider: item.name,
                    });
                    return;
                  }
                  item.onClick(e);
                }}
              >
                <img src={imgPath} alt={item.name} />
              </button>
            )}
            {checkSnsType === item.name && type === SnsBtnTypeCode.Login && (
              <SnsComment type={type} />
            )}
          </div>
        );
      })}
    </div>
  );
};
export default SnsButtons;
