import { format } from 'path/posix';

declare global {
  interface String {
    hexEncode(): string;
    hexDecode(): string;
    unicode(): string;
    toFirstUpperCase(): string;
    encodeString(publicKey: string): string;
    decodeString(publicKey: string): string;
    encode64(): string;
    decode64(): string;
  }
}

/**
 * @deprecated 보장안됨 미사용
 * String to Hex
 */
String.prototype.hexEncode = function () {
  var hex, i;
  var result = '';
  for (i = 0; i < this.length; i++) {
    hex = this.charCodeAt(i).toString(16);
    result += ('000' + hex).slice(-4);
  }
  return result;
};

/**
 * @deprecated 보장안됨 미사용
 * Hext to String
 */
String.prototype.hexDecode = function () {
  var hex = this;
  var str = '';
  for (var n = 0; n < hex.length; n += 2) {
    str += String.fromCharCode(parseInt(hex.substr(n, 2), 16));
  }
  return str;
};

/* -------------------------------------------------------------------------- */
// 복호화 암호화
/* -------------------------------------------------------------------------- */

/**
 * String to Unicode
 */
String.prototype.unicode = function () {
  return this.replace(/[\u007F-\uFFFF]/g, function (chr) {
    return `\\u${`0000${chr.charCodeAt(0).toString(16)}`.substr(-4)}`;
  });
};

/**
 * String to EncodeString
 */
String.prototype.encodeString = function (publicKey: string) {
  const enc = cipher(publicKey);
  return enc(this);
};

/**
 * Decode encryption string
 */
String.prototype.decodeString = function (publicKey: string) {
  const des = decipher(publicKey);
  return des(this);
};

/**
 * decode base 64
 */
String.prototype.decode64 = function () {
  return Buffer.from(this, 'base64').toString('binary');
};

/**
 * encode base 64
 */
String.prototype.encode64 = function () {
  return Buffer.from(this, 'binary').toString('base64');
};
/**
 * 첫글자 대문자 변환
 * String to Hex
 */
String.prototype.toFirstUpperCase = function () {
  const firstStr = this.substring(0, 1);
  let lastStr = '';
  if (this.length > 1) {
    lastStr = this.substring(this.length, 1);
  }

  return firstStr.toUpperCase() + lastStr;
};
const cipher = (salt: any) => {
  const textToChars = (text: any) =>
    text.split('').map((c: any) => c.charCodeAt(0));
  const byteHex = (n: any) => `0${Number(n).toString(16)}`.substr(-2);
  const applySaltToChar = (code: any) =>
    textToChars(salt).reduce((a: number, b: number) => a ^ b, code);

  return (text: any) =>
    text.split('').map(textToChars).map(applySaltToChar).map(byteHex).join('');
};

const decipher = (salt: any) => {
  const textToChars = (text: any) =>
    text.split('').map((c: string) => c.charCodeAt(0));
  const applySaltToChar = (code: any) =>
    textToChars(salt).reduce((a: number, b: number) => a ^ b, code);
  return (encoded: any) =>
    encoded
      .match(/.{1,2}/g)
      .map((hex: string) => parseInt(hex, 16))
      .map(applySaltToChar)
      .map((charCode: number) => String.fromCharCode(charCode))
      .join('');
};
