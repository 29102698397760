import { PaymentContext, ProductItemType } from '../context';

import AppManager from '@src/lib/app/AppManager';
import { PaymentWindowModeType } from '../data/_constants';
import { UserDefault } from '@src/lib';
import _ from 'lodash';
import { PaymentPrepareRequest } from '@src/lib/commonLib/api/client/V1/ApiPayment';

export interface ConfirmStripePopUpProps {
  request: PaymentPrepareRequest.PaymentPrepareType;
  selectItem: ProductItemType | null;
  loginId: string;
  isMobile: boolean;
  accessToken: string;
  provider: string;
  userId: string;
}

export namespace StripePurchase {
  export const stripeSeletor = (
    props: PaymentContext,
    isUseLastPayMethod: boolean,
  ) => {
    const { selectProductItem, loginInfo, token, isMobile } = props;
    if (!loginInfo || !selectProductItem) return;
    const { loginId, provider, userId } = loginInfo;

    const request: PaymentPrepareRequest.PaymentPrepareType = {
      accessToken: token,
      productId: selectProductItem?.productId || 0,
      pg: 'stripe',
      viewType: PaymentWindowModeType.LARGE,
      latestLocation: '/payment',
      CouponItem: null,
      isUseLastPayMethod,
    };
    /**
     * popup page에서 상품정보 사용하기 위해 저장
     */
    UserDefault.payment.setBuckets(request);

    if (AppManager.getInstance().isApp()) {
      const encodeSelectItem = JSON.stringify(request)
        .unicode()
        .encodeString('test');
      AppManager.getInstance().action.paymentStripe(
        `accessToken=${token}&provider=${provider}&userId=${userId}&loginId=${loginId}&selectItem=${encodeSelectItem}`,
      );
    } else {
      /**
       * 보안을 위해 자사 페이지로 팝업 띄운뒤, 결제 페이지로 redirect
       */
      window.open('/popup/stripe', 'payViewer', 'width=980, height=714');
    }
  };
}
