import { AxiosInstanceType, TopcoAxiosInstance } from "../topcoAxiosInstance";

export interface RequestContractInfo {
  comicId: number;
}

export default class ApiContract extends TopcoAxiosInstance {
  private GET_CONTRACT_LIST = "/api/v1/episode/getComicExpireContract";

  constructor(initValue: AxiosInstanceType) {
    super(initValue);
  }

  /**
   * 리퀘스트 api
   * @param comicId : 만화 고유 ID
   */
  public getContract = (comicId: number) => {
    const data: RequestContractInfo = {
      comicId,
    };

    return this.requst<RequestContractInfo>({
      method: "post",
      url: this.GET_CONTRACT_LIST,
      data: data,
    });
  };
}
