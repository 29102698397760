import { CloseBtn } from '@src/view/common/closeBtn';
import { ModalInstance } from '@src/lib/CustomModal/ModalContainer';
import { UserSession } from '@src/lib';
import { openRefillMiniPaymentModal } from './refilMiniPaymentModal';

const key = 'autoPayErrorModal';

interface RetryRefillType {
  orderId: string;
}

export const openRefillErrorModal = (props: RetryRefillType) => {
  ModalInstance.getInstance().push({
    key,
    shouldCloseOnOverlayClick: true,
    component: <RefillErrorModal {...props} />,
  });
};

export const closeRefillErrorModal = () => {
  ModalInstance.getInstance().delete(key);
};

const RefillErrorModal = (request: RetryRefillType) => {
  const { token } = UserSession.getUserInfo();
  const { orderId } = request;
  return (
    <div className="p-0 my-0 mx-auto bg-white rounded-[2px] text-[1rem] max-w-[322px] max-h-[90vh] w-[98vw] scrollbar-hide">
      <div className="w-full bg-white relative z-[1] rounded-none overflow-hidden break-all pt-[20px] px-[20px] pb-[23px]">
        <CloseBtn
          imgPath="/images/alert/close/ico_close.png"
          className="absolute top-[4px] right-[4px] text-transparent z-[1] cursor-pointer inline-block w-[35px] h-[35px]"
        />
        <div className="flex flex-col items-center">
          <div className="mb-[2.54px]">
            <img
              className="w-[250px]"
              src="/images/payment/auto_error_img.png"
              alt="auto_error_img"
            />
          </div>

          <div className="mt-[8px] mx-0 mb-[14px]">
            <div className="text-center font-[500] text-[18px] leading-[24px] tracking-[-0.2px] text-[#000000] break-keep">
              We’re sorry, an error has occurred while attempting to cycle your
              next auto-reload.
            </div>
          </div>

          <div className="mb-[22px]">
            <p className="text-[14px] leading-[18px] text-center tracking-[-0.2px] text-[#8F8F8F] break-keep">
              Please ensure your account has a sufficient balance, and register
              your card again.
            </p>
          </div>

          <div>
            <button
              type="button"
              className="max-w-[120px] py-[10px] px-[24px] bg-[#fff] text-[#000] text-[17px] border border-solid border-[#BBB] font-[500] tracking-[-0.2px]"
              onClick={(e: any) => {
                closeRefillErrorModal();
                openRefillMiniPaymentModal({ token, orderId });
              }}
            >
              Got it
            </button>{' '}
            <button
              type="button"
              className="max-w-[120px] py-[10px] px-[24px] bg-[#fff] text-[#000] text-[17px] border border-solid border-[#BBB] font-[500] tracking-[-0.2px]"
              onClick={(e: any) => {
                closeRefillErrorModal();
              }}
            >
              Not now
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RefillErrorModal;
