import Cookies from 'js-cookie';
import _ from 'lodash';
import useLogin from '../../../hooks/useLogin';

const useTestApi = () => {
  const { emailJoin, loginAfterAction } = useLogin();

  const accountUse = {
    getter: () => {
      if (Cookies.get('topco_test')) {
        const B = decodeURIComponent(Cookies.get('topco_test') || '');
        const Account = {
          id: B.split(';')[0]?.split('=')[1],
          password: B.split(';')[1]?.split('=')[1],
        };
        return Account;
      } else {
        return {
          id: '',
          password: '',
        };
      }
    },

    setter: (id: string, password: string | number) => {
      Cookies.set('topco_test', `id=${id};password=${password}`);
    },
  };

  const testSignup = () => {
    const randomStr = Math.random().toString(36).substring(2, 6);

    const Account = accountUse.getter();

    emailJoin(`${Account?.id}_${randomStr}@test.com`, `${Account?.password}`, {
      onLogin() {
        loginAfterAction();
      },
      onError(err) {
        console.error(err);
      },
    });
  };

  return { testSignup, accountUse };
};

export default useTestApi;
