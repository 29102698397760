import { AxiosInstanceType, TopcoAxiosInstance } from "../topcoAxiosInstance";

interface UpdatePushTopkenType {
  tokenId: string;
  appVersion: string;
}

/**
 * App과 연동되는 API 리스트
 */
export default class ApiApp extends TopcoAxiosInstance {
  private UPDATE_PUSH_TOKEN = "/app/updatePushToken";

  private UPDATE_DEVICE_ID = "/users/setUserDeviceId";

  constructor(initValue: AxiosInstanceType) {
    super(initValue);
  }

  /**
   * App push 정보 갱신 API
   * @param pushToken : push token
   * @param appVersion
   * @returns
   */
  public updatePushToken = (pushToken: string, appVersion: string) => {
    const data: UpdatePushTopkenType = {
      tokenId: pushToken,
      appVersion,
    };

    return this.requst<UpdatePushTopkenType>({
      method: "post",
      url: this.UPDATE_PUSH_TOKEN,
      data,
    });
  };

  /**
   * 웹 -> 앱 전환간 device id 갱신
   * @returns void
   */
  public updateDeviceId = (token: string) => {
    return this.requst({
      method: "post",
      url: this.UPDATE_DEVICE_ID,
    });
  };
}
