import { AxiosInstanceType, TopcoAxiosInstance } from '../topcoAxiosInstance';

interface SignUpRequestType {
  userId: string;
  password: string;
  auth: number;
  is17: boolean;
  deviceId: string;
  isAlreadyMature?: number; // 사전인증 체크 TODO:라이브후 수정
  partnerCode?: string;
  cToken?: string;
  // 통계용 임시 값
  actionType?: number;
  origin?: string;
  destination?: string;
}

/**
 * 회원가입 api
 * @param userId
 * @param password
 * @param auth : 성인 인증창 확인 유무
 * @param code : 파트너 코드
 * @param is17 : 17금 (구글) 파트너 코드 유무
 * @param deviceId : 고유값
 */
export default class ApiSignUp extends TopcoAxiosInstance {
  private SIGN_UP_URL = '/auth/SignUp';

  constructor(initValue: AxiosInstanceType) {
    super(initValue);
  }

  public signUpProc = (request: SignUpRequestType, cToken?: string) => {
    const data: SignUpRequestType = {
      userId: request.userId,
      password: request.password,
      auth: request.auth,
      is17: request.is17,
      deviceId: request.deviceId,
      partnerCode: request.partnerCode,
      isAlreadyMature: request.isAlreadyMature,
      cToken: cToken ?? '',
      actionType: request.actionType,
      origin: request.origin,
      destination: request.destination,
    };

    if (!request.actionType) {
      delete data.actionType;
      delete data.origin;
      delete data.destination;
    }
    if (typeof data.isAlreadyMature === 'undefined') {
      delete data.isAlreadyMature;
    }
    if (data.partnerCode?.length === 0) {
      delete data.partnerCode;
    }

    return this.requst<SignUpRequestType>({
      method: 'post',
      url: this.SIGN_UP_URL,
      data,
    });
  };
}
