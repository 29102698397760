import { AxiosInstanceType, TopcoAxiosInstance } from '../topcoAxiosInstance';

export interface BannerClickType {
  location: string;
  bannerId: number;
  comicId: number;
  episodeId: number;
}

export default class ApiBanner extends TopcoAxiosInstance {
  private GET_PAYMENT_BANNER = '/api/v1/page/main/paymentBanner';

  private UPDATE_BANNER_CLICK = '/statistics/bannerClick';

  constructor(initValue: AxiosInstanceType) {
    super(initValue);
  }

  /**
   * banner
   * @param request
   * @returns
   */
  public getPaymentBanner = () => {
    return this.requst<null>({
      url: this.GET_PAYMENT_BANNER,
    });
  };

  // TODO:22.07.12 한달 후 삭제 될 수도..
  public updateBannerClick = (data: BannerClickType) => {
    return this.requst<BannerClickType>({
      method: 'post',
      url: this.UPDATE_BANNER_CLICK,
      data,
    });
  };
}
