import { AxiosInstanceType, TopcoAxiosInstance } from '../topcoAxiosInstance';

interface DeleteUserType {
  userId: string;
}

interface FindPasswordType {
  loginId: string;
}

interface UpdatePasswordType {
  currentPassword: string;
  newPassword: string;
}

interface UpdateNickNameType {
  name: string;
}

interface UserEpisodeOrderType {
  episodeOrder: 'ASC' | 'DESC';
}

interface RequestGetType {
  language: string;
  page: number;
  limit: number;
}

interface UpdateEmailSubscribeType {
  userId: number;
  isReceiveMail: number;
}

interface UserValidationCheckType {
  userId: number;
}

interface SiteReocationType {
  before: string;
  after: string;
  redirectUrl: string;
}

interface RevokeTokenCheckType {
  revokeToken: string;
  checkType: string;
  checkValue: string;
}

export default class ApiUser extends TopcoAxiosInstance {
  private GET_USER_INFO = '/users';

  private RESET_PASSWORD = '/users/resetPassword';

  private GET_COIN_INFO = '/coin/getCurrentCoin';

  private GET_MY_DATA = '/api/v1/page/my';

  private GET_USER_COIN_HISTORY = 'api/v1/page/my/getUserEpisodePurchaseList';

  private GET_USER_REQUEST = '/api/v1/userRequest/getUserRequestList';

  private UPDATE_USER_EPISODE_ORDER = '/users/setEpisodeOrder';

  private UPDATE_PASSWORD = '/users/changePassword';

  private UPDATE_USER_PASSWORD = '/users/change/password';

  private UPDATE_NICKNAME = 'users/changename';

  private DELETE_USER = 'users/delete';

  private GLOBAL_INFO = 'api/v2/global/user';

  private GET_ATTENDANCE = '/api/v1/attendance';

  private UPDATE_EMAIL_SUBSCRIBE = '/users/setUserIsReceiveMail';

  private USER_VALIDATION_CHECK = '/validation/token';

  // visitor Id 검증
  private VALIDATED_VISITOR_ID = '/validation/visitorId';

  // 사이트 이관 유입 통계
  private SITE_RELOCATION = '/statistics/relocation';

  // 뷰어 자동 스크롤 클릭 통계
  private CLICK_AUTO_SCROLL = '/statistics/autoScroll';

  // 자동로그인 관련
  private USER_REVOKE_TOKEN = '/auth/revokeToken';
  private AUTO_LOGIN_PROC = '/auth/revokeToken';
  private GET_USER_VISITORID = '/users/getUserByVisitorId';

  // 정기구독 취소
  private CANCEL_PAYMENT_RECURRING = '/payment/cancel/recurring';

  // 자동 충전 데이터
  private USER_PAYMENT_REFILL = '/payment/reFill';

  constructor(initValue: AxiosInstanceType) {
    super(initValue);
  }

  /**
   * 비밀번호 찾기
   * @param loginId
   * @returns
   */
  public findPassword = (loginId: string) => {
    const data: FindPasswordType = {
      loginId,
    };

    return this.requst<FindPasswordType>({
      method: 'post',
      url: this.RESET_PASSWORD,
      data,
    });
  };

  /**
   * user info 정보
   * @returns
   */
  public getUserInfo = () => {
    return this.requst<null>({
      method: 'post',
      url: this.GET_USER_INFO,
    });
  };

  /**
   * user coin 정보
   * @returns
   */
  public getCoinInfo = () => {
    return this.requst<null>({
      method: 'post',
      url: this.GET_COIN_INFO,
    });
  };

  /**
   * 마이페이지 데이터 획득
   * @param offset
   * @returns
   */
  public getMyData = (offset: number) => {
    return this.requst<null>({
      headers: { offset },
      url: this.GET_MY_DATA,
    });
  };

  /**
   * 마이페이지 자동 충전 데이터 획득
   * @param request
   * @returns
   */
  public updateRefillStatus = (request: any) => {
    return this.requst<null>({
      method: 'post',
      url: `${this.USER_PAYMENT_REFILL}/status`,
      data: request,
    });
  };

  /**
   * user coin history
   * @param language
   * @param offset
   */
  public getUserCoinHistory = (offset: number) => {
    return this.requst<null>({
      headers: { offset },
      url: this.GET_USER_COIN_HISTORY,
    });
  };

  /**
   * 1:1 Q&A 더보기 클릭 시 리스트 가져오는 api
   * @param request
   * @returns
   */
  public getUserRequest = (request: RequestGetType) => {
    return this.requst<RequestGetType>({
      method: 'post',
      url: this.GET_USER_REQUEST,
      data: request,
    });
  };

  /**
   * 사용자 회차리스트 기본 정렬 api
   * @param episodeOrder
   * @returns
   */
  public updateUserEpisodeOrder = (episodeOrder: 'ASC' | 'DESC') => {
    const data: UserEpisodeOrderType = {
      episodeOrder,
    };

    return this.requst<UserEpisodeOrderType>({
      method: 'post',
      url: this.UPDATE_USER_EPISODE_ORDER,
      data,
    });
  };

  /**
   * 사용자 패스워드 업데이트 api
   * @param currentPassword
   * @param newPassword
   */
  public updateUserPassword = (
    currentPassword: string,
    newPassword: string
  ) => {
    const data: UpdatePasswordType = {
      currentPassword,
      newPassword,
    };

    return this.requst<UpdatePasswordType>({
      method: 'post',
      url: this.UPDATE_PASSWORD,
      data,
    });
  };

  /**
   * 사용자 패스워드 업데이트 api 개선 버전
   * 실패 시 200이 아닌 500에러로 메세지 전달 함
   * @param currentPassword
   * @param newPassword
   */
  public updateUserPasswordV2 = (
    currentPassword: string,
    newPassword: string
  ) => {
    const data: UpdatePasswordType = {
      currentPassword,
      newPassword,
    };

    return this.requst<UpdatePasswordType>({
      method: 'post',
      url: this.UPDATE_USER_PASSWORD,
      data,
    });
  };

  /**
   * 사용자 nickname 업데이트 api
   * @param name
   * @returns
   */
  public updateNickName = (name: string) => {
    const data: UpdateNickNameType = {
      name,
    };

    return this.requst<UpdateNickNameType>({
      method: 'post',
      url: this.UPDATE_NICKNAME,
      data,
    });
  };

  /**
   * 회원탈퇴
   * @param userId
   * @returns
   */
  public deleteUser = (userId: string) => {
    const data: DeleteUserType = {
      userId,
    };

    return this.requst<DeleteUserType>({
      url: this.DELETE_USER,
      method: 'post',
      data,
    });
  };

  /**
   * 정기구독 취소(현재 membership만 사용 중)
   * @param orderId
   */
  public cancelPaymentRecurring = (orderId: string) => {
    return this.requst<string>({
      method: 'post',
      url: `${this.CANCEL_PAYMENT_RECURRING}/${orderId}`,
    });
  };

  /**
   * loginCount + getCurrentCoin + globalProcess API
   * @returns
   */
  public getGlobalInfo = () => {
    return this.requst<null>({
      url: this.GLOBAL_INFO,
      method: 'post',
    });
  };

  /**
   * 출첵
   * @returns
   */
  public getAttendanceList = () => {
    return this.requst<null>({
      url: this.GET_ATTENDANCE,
      method: 'post',
    });
  };

  public updateEmailSubscribe = (userId: number, isReceiveMail: number) => {
    const data: UpdateEmailSubscribeType = {
      userId,
      isReceiveMail,
    };
    return this.requst<UpdateEmailSubscribeType>({
      url: this.UPDATE_EMAIL_SUBSCRIBE,
      method: 'post',
      data,
    });
  };

  /**
   * userId와 token의 유효성 체크
   * @param loginId
   * @returns
   */
  public userValidationCheck = (userId: number) => {
    const data: UserValidationCheckType = {
      userId,
    };

    return this.requst<UserValidationCheckType>({
      method: 'post',
      url: this.USER_VALIDATION_CHECK,
      data,
    });
  };

  /**
   * fingerPrint 유효성 검증 (없으면 재발급 reload 활용)
   */
  public checkValidatedVisitorId = () => {
    return this.requst({
      method: 'get',
      url: this.VALIDATED_VISITOR_ID,
    });
  };

  /**
   * site 유입 통계 (globalToptoon, toptoonpluse에서 유입된 url)
   * @param before: 유입 되는 hostName
   * @param after: 목적지 hostName
   * @param redirectUrl: 목적지 url
   */
  public checkSiteRelocation = (
    before: string,
    after: string,
    redirectUrl: string
  ) => {
    return this.requst<SiteReocationType>({
      url: this.SITE_RELOCATION,
      method: 'post',
      data: {
        before,
        after,
        redirectUrl,
      },
    });
  };

  /**
   * 뷰어 자동 스크롤 클릭 통계
   * @returns
   */
  public checkAutoScroll = () => {
    return this.requst<null>({
      url: this.CLICK_AUTO_SCROLL,
    });
  };

  /*********************************************************************************
   * => ANCHOR : 자동로그인 관련
   *********************************************************************************/
  /**
   * @param data - {token:string, revokeToken:string}
   * @returns 현재 토큰을 취소 후 재발급한 결과 값
   */
  public checkRevokeToken = (revokeToken: string) => {
    return this.requst<{ revokeToken: string }>({
      method: 'post',
      url: this.USER_REVOKE_TOKEN,
      data: { revokeToken },
    });
  };

  /**
   * TODO: checkRevokeToken => 함수 이름 변경을 위해 임시로 추가, 추후 checkRevokeToken 함수 삭제
   * @param data - {token:string, revokeToken:string}
   * @returns 현재 토큰을 취소 후 재발급한 결과 값
   */
  public autoLoginProc = (revokeToken: string) => {
    return this.requst<{ revokeToken: string }>({
      method: 'post',
      url: this.AUTO_LOGIN_PROC,
      data: { revokeToken },
    });
  };

  /**
   * fingerprintId로 기존 가입된 아이디가 있는지 확인
   * @param fingerprintId
   * @returns
   */
  public getUserByVisitorId = (fingerprintId: string) => {
    return this.requst<{ 'visitor-id': string }>({
      method: 'post',
      url: this.GET_USER_VISITORID,
      data: { 'visitor-id': fingerprintId },
    });
  };
}
