const createHash = require('create-hash');
const pbkdf2 = require('pbkdf2-compat');

export class Hash {
  static base64Encode(str: string): string {
    return Buffer.from(str, 'binary').toString('base64');
  }

  static base64Decode(str: string) {
    return Buffer.from(str, 'base64').toString('binary');
  }

  /**
   * 접근 허용 유무가 필요한 api에 대한 유효 키를 생성하는 function
   * @param timestamp : 현재시간
   * @param device : 기기 식별값
   * @param iteration
   * @returns
   */
  static convertApiKey(
    timestamp: number,
    device: string,
    iteration: number,
  ): string {
    try {
      const salt = createHash('sha256')
        .update(device.toString().replace(/-/g, `${timestamp}`))
        .digest('hex');
      return pbkdf2
        .pbkdf2Sync(
          `${device}|${timestamp}`,
          salt,
          iteration,
          salt.length,
          'sha512',
        )
        .toString('base64');
    } catch (e) {
      console.warn('apiKeyWarn', e);
      return '';
    }
  }
}
