import { AxiosInstanceType, TopcoAxiosInstance } from "../topcoAxiosInstance";

type ModalActionType = "viewed" | "confirmed";
export interface ModalPropsType {
  code: string;
  action: ModalActionType;
}

export default class ApiModal extends TopcoAxiosInstance {
  private MODAL_CHECK = "/api/v1/modal/log";

  constructor(initValue: AxiosInstanceType) {
    super(initValue);
  }

  public modalCheck = ({ code, action }: ModalPropsType) => {
    return this.requst({
      method: "post",
      url: `${this.MODAL_CHECK}/${code}/${action}`,
    });
  };
}
