import { PaymentContext, ProductItemType } from '../context';

import AppManager from '@src/lib/app/AppManager';
import { PaymentWindowModeType } from '../data/_constants';

import { UserDefault } from '@src/lib';
import _ from 'lodash';
import ApiPayment, {
  PaymentPrepareRequest,
} from '@src/lib/commonLib/api/client/V1/ApiPayment';
import { TypeCheck } from '@src/lib/commonLib/TypeCheck';

export interface ConfirmEximbayPopUpProps {
  loginId: string;
  isMobile: boolean;
  selectItem: ProductItemType;
  request: PaymentPrepareRequest.PaymentPrepareType;
  payType?: string;
  paymethod?: string;
}

export interface EximbayRecurringModelPopUp {
  selectPid: number;
  selectCoupon: any | null;
  accessToken: string;
  windowType: string;
  loginId: string;
  isMobile: boolean;
  selectItem: ProductItemType;
}

export namespace EximbayPurchase {
  /**
   * 일반 결제 페이지 호출
   * @param props
   * @returns
   */
  export const selectorForPopup = (
    props: PaymentContext,
    paymethod: string | null,
    isUseLastPayMethod: boolean,
  ) => {
    const { selectProductItem, loginInfo, token, isMobile } = props;

    if (!loginInfo || !selectProductItem) return;

    const request: PaymentPrepareRequest.PaymentPrepareType = {
      accessToken: token,
      productId: selectProductItem?.productId || 0,
      pg: 'eximbay',
      viewType: PaymentWindowModeType.LARGE,
      latestLocation: '/payment',
      CouponItem: null,
      isUseLastPayMethod,
    };

    const { loginId, provider, userId } = loginInfo;

    const selectObj = {
      loginId,
      isMobile,
      selectItem: selectProductItem,
      request,
      paymethod: paymethod ?? '',
      /* payType: 'eximbay', */
    };
    UserDefault.payment.setBucketsNormal(selectObj); // 상품 정보 저장

    // TODO: app
    if (AppManager.getInstance().isApp()) {
      const encodeSelectItem = JSON.stringify(selectObj)
        .unicode()
        .encodeString('test');

      AppManager.getInstance().action.paymentPopup(
        `accessToken=${token}&provider=${provider}&userId=${userId}&loginId=${loginId}&selectItem=${encodeSelectItem}`,
      );
    } else {
      window.open('/popup', 'payViewer', 'width=980, height=714');
    }
  };

  export const confirmPopUp = (props: ConfirmEximbayPopUpProps) => {
    const mid = process.env.REACT_APP_EXIMBAY_MID;

    const { loginId, isMobile, selectItem, request, payType, paymethod } =
      props;

    let eMail = loginId;
    if (!`${loginId}`.includes('@')) {
      eMail = `${loginId}@toptoon.com`;
    }

    const requestDefault = {
      ver: 230,
      mid,
      txntype: 'PAYMENT', // payment or authorize
      cur: selectItem.currencyCode ?? 'USD', // currency
      amt: selectItem.price, // 결제 금액
      buyer: loginId || '',
      email: eMail === '' ? 'helper@toptoon.com' : eMail, // !! 이메일이 없을 경우 오류로 처리됨 기본 메일 임의 등록
      lang: 'EN', // 결제정보 언어 타입
      ostype: isMobile ? 'M' : 'P', // PC, Mobile 구분
      returnurl: `${process.env.REACT_APP_BASE_URL}/payment/confirm/eximbay`, // 결제 창 종료 후 호출 가맹점 페이지
      statusurl: `${process.env.REACT_APP_BASE_URL}/payment/confirm/eximbay`, // 결제 처리가 끝나면 Backend로 호출되는 가맹점 페이지
      item_1_product: selectItem.name,
      item_1_quantity: '1',
      item_1_unitPrice: selectItem.price,
      item_1_link: `//${window.location.host}/payment`,
      surcharge_1_name: selectItem.name,
      surcharge_1_quantity: '1',
      surcharge_1_unitPrice: selectItem.price,
      displaytype: 'R',
      paymethod,
      // paymethod: payType, // default 없을 시 카드 번호에 따라 자동 인식됨
    };

    if (!paymethod || paymethod === '') delete requestDefault['paymethod'];

    const requestMerge = {
      ...request,
      eximbayData: { ...requestDefault },
    };

    return new ApiPayment(UserDefault.getUserSession())
      .paymentPrepare(requestMerge)
      .then(res => res.data)
      .then(res => {
        const { data, response } = res;

        // TODO: 정리
        if (_.has(data, 'message')) {
          return {
            message: data.message,
          };
        }

        const { orderId, fgkey, eximbayData } = data;

        /**
         * GTM - 결제 정보 로컬스토리지에 저장
         */
        const pay_info = {
          id: orderId,
          value: Number(eximbayData.amt),
          currency: eximbayData.cur,
        };
        UserDefault.gtmPayInfo.setter(pay_info);

        // 결제 요청 만들기
        const result = {
          ref: orderId,
          fgkey: _.toUpper(fgkey),
          ...eximbayData,
        };

        // 결제 요청 처리
        return {
          ...result,
        };
      });
  };

  /**
   * 멤버쉽 결제 페이지 호출
   */
  export const selectorForPopupRecurring = (context: PaymentContext) => {
    const {
      selectProductItem,
      selectCoupon,
      loginInfo,
      token,
      windowMode,
      isMobile,
    } = context;

    if (!selectProductItem || !loginInfo) return;

    const { loginId, provider, userId } = loginInfo;

    const eximbay: EximbayRecurringModelPopUp = {
      selectPid: Number(selectProductItem?.productId),
      selectCoupon,
      accessToken: token,
      windowType: windowMode ?? PaymentWindowModeType.LARGE,
      loginId: loginInfo?.loginId ?? '',
      isMobile: isMobile ?? false,
      selectItem: selectProductItem,
    };

    UserDefault.payment.setBuckets(eximbay); // 상품 정보 저장

    if (AppManager.getInstance().isApp()) {
      const encodeSelectItem = JSON.stringify(eximbay)
        .unicode()
        .encodeString('test');

      const rUri = '/';

      AppManager.getInstance().action.paymentRecurring(
        `accessToken=${token}&provider=${provider}&userId=${userId}&loginId=${loginId}&selectItem=${encodeSelectItem}&redirectUri=${rUri}`,
      );
    } else {
      window.open('/popup/recurring', 'payViewer', 'width=980, height=714');
    }
  };

  export const recurringPopup = async (arg: EximbayRecurringModelPopUp) => {
    const {
      selectPid,
      selectCoupon,
      loginId,
      accessToken,
      windowType,
      selectItem,
      isMobile,
    } = arg;

    const mid = process.env.REACT_APP_EXIMBAY_RECURRING_MID;

    let eMail = loginId;
    if (!`${loginId}`.includes('@')) {
      eMail = `${loginId}@toptoon.com`;
    }

    // 결제 요청한 페이지 정보 (백앤드 전달용)
    const latestLocation = `${window.location.href}`;

    // prepare 호출
    const request: PaymentPrepareRequest.PaymentPrepareType = {
      accessToken,
      productId: selectPid,
      pg: 'eximbay',
      viewType: `${windowType}`,
      latestLocation,
      CouponItem: selectCoupon || '',
    };

    const requestDefault = {
      ver: 230,
      mid,
      txntype: 'PAYMENT', // payment or authorize
      cur: selectItem.currencyCode ?? 'USD', // currency
      amt: selectItem.price, // 결제 금액
      buyer: loginId || '',
      email: eMail === '' ? 'helper@toptoon.com' : eMail, // !! 이메일이 없을 경우 오류로 처리됨 기본 메일 임의 등록
      lang: 'EN', // 결제정보 언어 타입
      ostype: isMobile ? 'M' : 'P', // PC, Mobile 구분
      returnurl: `${process.env.REACT_APP_BASE_URL}/payment/confirm/eximbay`, // 결제 창 종료 후 호출 가맹점 페이지
      statusurl: `${process.env.REACT_APP_BASE_URL}/payment/confirm/eximbay`, // 결제 처리가 끝나면 Backend로 호출되는 가맹점 페이지
      item_1_product: selectItem.name,
      item_1_quantity: '1',
      item_1_unitPrice: selectItem.price,
      item_1_link: `//${window.location.host}/payment`,
      surcharge_1_name: selectItem.name,
      surcharge_1_quantity: '1',
      surcharge_1_unitPrice: selectItem.price,
      displaytype: 'R',
      recurringPayment: 'Y', // AN 1 자동결제 여부. (Y:사용, N:사용안함)
      recurringAmount: selectItem.price, // N 10, 2 자동결제 총 금액. (e.g. 10.50, 9.15) 금액 3자리 구분 ‘,’는 사용하지 않음.
      // recurringDate: recurringDateForEximbay(), // AN 8 YYYYMMDD. 자동결제 시작일이 결제요청일자와 다른 경우 사용. 자동결제 시작일(다음 결제일)은 현재일자 이후 여야하며, 이후로는 1달 후 자동결제 됨.
      recurringURL: `${process.env.REACT_APP_BASE_URL}/payment/confirm/eximbay`, // AN 255 자동결제 후 호출되는 가맹점 페이지
      recurringEmail: 3,
    };

    const eximbayData = { ...requestDefault };

    const requestMerge = {
      ...request,
      eximbayData,
    };

    return new ApiPayment(UserDefault.getUserSession())
      .paymentPrepareRecurring(requestMerge)
      .then(res => res.data)
      .then(res => {
        if (TypeCheck.itemsByPath(res, 'data')) {
          const { data, response } = res;
          const { orderId, fgkey, eximbayData } = data;

          /**
           * GTM - 결제 정보 로컬스토리지에 저장
           */
          const pay_info = {
            id: orderId,
            value: Number(eximbayData.amt),
            currency: eximbayData.cur,
          };
          UserDefault.gtmPayInfo.setter(pay_info);

          // 결제 요청 만들기
          const result = {
            ref: orderId,
            fgkey: _.toUpper(fgkey),
            ...eximbayData,
          };

          // 결제 요청 처리
          return {
            ...result,
          };
        }
      });
  };
}
