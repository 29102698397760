import { AxiosInstanceType, TopcoAxiosInstance } from '../topcoAxiosInstance';

export default class ApiFaq extends TopcoAxiosInstance {
  private GET_FAQ_LIST = 'api/v1/faq/list';

  constructor(initValue: AxiosInstanceType) {
    super(initValue);
  }

  /**
   * FAQ 리스트
   * @param request
   * @returns
   */
  public getFaqList = () => {
    return this.requst<null>({
      method: 'post',
      url: this.GET_FAQ_LIST,
    });
  };
}
