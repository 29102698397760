import { AxiosInstanceType, TopcoAxiosInstance } from "../topcoAxiosInstance";

export interface RequestType {
  isAlreadyMature?: boolean;
  Cookies?: object;
  path?: string;
}

export default class ApiEvent extends TopcoAxiosInstance {
  private GET_EVENT_MODAL = "/api/v1/modal";

  private GET_GLOBAL_PROCESS = "/api/v1/globalProcess";

  private GET_EVENT_MODAL_CHECK = "/api/v1/modal/check";

  private GET_CASHBACK_COINSUM = "/api/v1/event/cashback/coinsum";

  private GET_EVENT_CATEGORY = "/api/v1/event";

  private GET_EVENT_BANNER_DATA_URL = "/api/v1/page/event";

  private UPDATE_PARTNER = "/log/setPartnerCode";

  private GET_USER_ID_PROMOTION_TARGET =
    "/api/v1/event/getUserIdPromotionTarget";

  private PUT_USER_ID_PROMOTION = "/api/v1/event/putUserIdPromotion";

  private EVENT_JP_TWO_BOOK_CAMPAIGN = "/api/v1/event/jpTwoBookCampaign";

  private EVENT_JP_THREE_BOOK_CAMPAIGN = "/api/v1/event/jpThreeBookCampaign";

  private GET_INVITE_REWARD = "/api/v2/event/inviteRanking";

  private UPDATE_INVITE_CODE = "/api/v2/event/attendInviteEvent";

  constructor(initValue: AxiosInstanceType) {
    super(initValue);
  }

  /**
   * 일본 이벤트 페이지 2권짜리
   * @returns
   */
  public jpTwoBookCampaign() {
    return this.requst({
      url: this.EVENT_JP_TWO_BOOK_CAMPAIGN,
    });
  }

  /**
   * 일본 이벤트 페이지 3권짜리
   * @returns
   */
  public jpThreeBookCampaign() {
    return this.requst({
      url: this.EVENT_JP_THREE_BOOK_CAMPAIGN,
    });
  }

  /**
   * 이탈자 방지 API
   * @param reqHeader
   * @param cancel
   * @returns
   */
  public getUserIdPromotionTarget = (comicId: number) => {
    return this.requst<{ comicId: number }>({
      method: "post",
      data: {
        comicId,
      },
      url: this.GET_USER_ID_PROMOTION_TARGET,
    });
  };

  /**
   * 이탈자 방지 프로모션 지급
   * @param reqHeader
   * @param cancel
   * @returns
   */
  public putUserIdPromotion = (comicId: number) => {
    return this.requst<{ comicId: number }>({
      method: "post",
      data: {
        comicId,
      },
      url: this.PUT_USER_ID_PROMOTION,
    });
  };

  /**
   * @deprecated
   * 사용자가 확인해야할 event가 있는지 확인하는 api
   */
  public getEventModal = () => {
    return this.requst<null>({
      url: this.GET_EVENT_MODAL,
    });
  };

  /**
   * getEventModal과 병합
   * @param reqHeader
   * @param cancel
   * @returns
   */
  public getGLobalProcess = (reqHeader: RequestType) => {
    const { Cookies, path } = reqHeader;

    return this.requst<object | null>({
      method: "post",
      url: this.GET_GLOBAL_PROCESS,
      data: Cookies ? { Cookies: { ...Cookies }, path } : null,
    });
  };

  /**
   * 사용자 이벤트 확인 api
   * @param id (이벤트 식별 id)
   */
  public checkEventModal = (id: string) => {
    return this.requst<any>({
      method: "post",
      url: this.GET_EVENT_MODAL_CHECK,
      data: {
        code: id,
      },
    });
  };

  /**
   * 파트너 코드 유입 통계용 api
   * @param pCode
   */
  public updatePartnerCode = (params: { partnerCode: string }) => {
    return this.requst<null>({
      url: this.UPDATE_PARTNER,
      params,
    });
  };

  /**
   * cashback 코인소모 이벤트 페이지 api
   * @returns
   */
  public getCashbackCoinSum = (code: string) => {
    return this.requst<null>({
      url: this.GET_CASHBACK_COINSUM,
      params: {
        code,
      },
    });
  };

  /**
   * TODO 1회성 혹은 즉흥적인 event Api에 대하여 category로 대응하기 위하여 생성
   * @param category
   * @returns
   */
  public getEventCategory = (category: string) => {
    return this.requst<null>({
      url: `${this.GET_EVENT_CATEGORY}/${category}`,
    });
  };

  public getEventBannerData = () => {
    return this.requst<null>({
      url: this.GET_EVENT_BANNER_DATA_URL,
    });
  };

  public eventThemeImageSort = () => {
    return this.requst<null>({
      url: this.GET_EVENT_BANNER_DATA_URL,
    });
  };

  /**
   * 사용자 미친 결제 이벤트 확인 api
   * @param id (이벤트 식별 id)
   */
  public checkCrazyEventModal = (id: number) => {
    return this.requst<any>({
      method: "post",
      url: this.GET_EVENT_MODAL_CHECK,
      data: {
        eventId: id,
      },
    });
  };

  /**
   * ANCHOR: 친구초대페이지 api
   * @returns
   */
  public getInviteReward = () => {
    return this.requst<any>({
      method: "get",
      url: this.GET_INVITE_REWARD,
    });
  };

  /**
   * ANCHOR: 친구초대 코드 등록 api
   * @param inviteCode
   * @returns
   */
  public updateInviteCode = (inviteCode: string) => {
    const data: { inviteCode: string } = {
      inviteCode,
    };
    return this.requst<{ inviteCode: string }>({
      method: "post",
      url: this.UPDATE_INVITE_CODE,
      data,
    });
  };
}
