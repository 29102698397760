import _ from 'lodash';

export const checkUndefined = (obj: Record<string, any> | undefined) => {
  if (obj) {
    Object.keys(obj).forEach((key: string) => {
      if (obj[key] && typeof obj[key] === 'object') {
        checkUndefined(obj[key]);
      } else if (typeof obj[key] === 'undefined') {
        throw `${key} is undefined`;
      }
    });
  }
};

export const deleteUndefined = (obj: Record<string, any> | undefined): void => {
  if (obj) {
    Object.keys(obj).forEach((key: string) => {
      if (obj[key] && typeof obj[key] === 'object') {
        deleteUndefined(obj[key]);
      } else if (typeof obj[key] === 'undefined') {
        delete obj[key]; // eslint-disable-line no-param-reassign
      }
    });
  }
};

export class TypeCheck {
  // 기본 설정값 문자열 오류
  static INVALID_STRING: string = 'invalidValue';

  // 기본 설정값 숫자 오류
  static INVALID_NUM: number = -1;

  // 설정된 값 없음
  static NUMBER_NOT_SET: number = 0;

  // Array 검사
  static isValidArray(id?: any): boolean {
    if (!this.isValidObject(id)) {
      return false;
    }

    if (!_.isArray(id)) {
      return false;
    }

    if (id?.length === 0) {
      return false;
    }

    return true;
  }

  // Object 검사
  static isValidObject(id?: unknown): boolean {
    if (_.isNull(id)) {
      return false;
    }
    if (_.isUndefined(id)) {
      return false;
    }

    // if (Object.keys(id).length === 0) return false;

    return true;
  }

  // 문자열 검사
  static isValidString(id?: string): boolean {
    if (!this.isValidObject(id)) {
      return false;
    }

    if (id === '') {
      return false;
    }

    return true;
  }

  // 숫자 검사
  static isValidNumber(id: number): boolean {
    if (_.isNaN(id)) {
      return false;
    }

    if (id === this.INVALID_NUM || id === this.NUMBER_NOT_SET || id < 0) {
      return false;
    }

    return true;
  }

  static itemsByPath(origin: any, args: string): any {
    // origin 유효성 검증
    if (this.isValidObject(origin) === false) {
      return null;
    }

    // argument 분리
    const items = args.split('.');

    // argument 사이즈 검증
    if (items.length > 0) {
      // hasOwnProperty
      if (!_.has(origin, items[0])) {
        return null;
      }

      // set value
      const target = origin[items[0]];

      // param 검증
      if (_.isUndefined(target)) {
        return null;
      }

      if (_.isNull(target)) {
        return null;
      }

      // 배열일 경우 처리
      if (_.isArray(target)) {
        if (this.isValidArray(target)) {
          return target;
        }
        return [];
      }

      // depth 검증
      if (items.length === 1) {
        return target;
      }

      items.shift();

      return this.itemsByPath(target, items.toString().replace(',', '.'));
    }
    return args;
  }
}
