import { PaymentContext, ProductItemType } from '../context';

import AppManager from '@src/lib/app/AppManager';
import { UserDefault } from '@src/lib';

// TODO : 자동충전 TEST
export namespace PaymentWallRefillRetryPurchase {
  export const PaymentWallRefillRetryPurchase = (
    context: PaymentContext,
    payMethod: string,
    orderId: number,
  ) => {
    const { selectCoupon, loginInfo, windowMode, isMobile } = context;

    if (!loginInfo) return;
    console.log('ee');
    const { token, loginId, provider, userId } = loginInfo;

    const selectObj = {
      accessToken: token,
      // pg: 'paymentwall',
      // viewType: windowMode ?? PaymentWindowModeType.LARGE,
      // latestLocation: `${window.location.pathname}` ?? '/',
      payMethod: payMethod,
      orderId,
    };

    // 상품정보 저장
    UserDefault.payment.setBuckets(selectObj);

    if (AppManager.getInstance().isApp()) {
      const encodeSelectItem = JSON.stringify(selectObj)
        .unicode()
        .encodeString('test');

      const rUri = AppManager.getInstance().currentPage.getter();
      AppManager.getInstance().action.paymentPopup(
        `accessToken=${token}&provider=${provider}&userId=${userId}&loginId=${loginId}&selectItem=${encodeSelectItem}&redirectUri=${rUri}&pgString=paymentWallRefillRetry`,
      );
    } else {
      const windowSize =
        payMethod === 'gPay'
          ? `width=980, height=500`
          : 'width=980, height=714';
      window.open(
        '/popup/paymentWallRefillRetry',
        'payViewer',
        `${windowSize}`,
      );
    }
  };
}
