import axios, { AxiosRequestConfig } from 'axios';
import Cookies from 'js-cookie';
import TagManager from 'react-gtm-module';
import _ from 'lodash';
import { TypeCheck } from '../TypeCheck';
const createHash = require('create-hash');

export enum Event {
  PAGEVIEW = 'pageview', // 페이지뷰
  JOIN = 'joinComplete', // 가입완료
  LOGIN = 'loginComplete', // 로그인
  OPEN_JOIN_MODAL = 'openJoinModal', // 가입 모달 노출
  JOIN_BUTTON_CLICK = 'CompleteRegistration', // 가입 버튼 클릭
  PURCHASE = 'payComplete', // 결제완료
  PURCHACE_COMPLETE_MODAL = 'purchaseCompleteModal', // 결제완료모달 진입시
  PURCHASE_BUTTON_CLICK = 'InitiateCheckout', // 결제 버튼 클릭
  VIEW_EPISODE = 'viewEpisode', // 회차 열람
  VIEW_EPISODELIST = 'viewEpisodeList', // 회차리스트 열람
  VIEW_CATEGORY = 'viewCategory', // 카테고리 페이지 열람
  CALL_API = 'callApi', // api 호출 (개발팀 체크용)
  VIEW_MAIN = 'viewMain', // 메인페이지 열람
  NO_VISITOR_ID = 'noVisitorid', // visitor id 없음
  MISSION_COMPLETE = 'missionComplete', // 무료충전소 미션 성공
  CLICK_CONTINUE_EPI = 'clickContinueEpi', // 에피소드 이어보기 클릭
}

export interface GtmSetType {
  event: Event;
  pCode?: string; // 파트너 코드
  params?: any; // 커스텀 매개변수
  s2?: string | null; // dv360 clickId
  rid?: string | null; // i-mobile session id
}

/**
 * global/jp 에서 사용할 Gtm 추상클래스 정의
 */
export abstract class Gtm {
  constructor(id?: string) {
    if (id) {
      TagManager.initialize({
        gtmId: id,
      });
    }
  }

  /**
   * Google Tag Manager로 전송
   * @param props
   * @returns
   */
  protected sendToGtm(props: GtmSetType, fbEventId: string = ''): void {
    const { event, pCode, params } = props;

    TagManager.dataLayer({
      dataLayer: {
        event,
        partnerCode: pCode,
        eventModel: {
          event_id: fbEventId,
        },
        ...params,
      },
    });
  }

  /**
   * DV360 Server-to-server PostBack
   * @param event event name
   * @param s2 clickId
   */
  protected sendToDv360(event: Event, s2: string | null = null): void {
    if (!s2) return;

    switch (event) {
      case Event.JOIN:
        fetch(
          `/dv360/postback?cid=${s2}&payout=OPTIONAL&currency=OPTIONAL&txid=OPTIONAL`,
        );
        break;
    }
  }

  /**
   * 고유한 이벤트 id 획득
   * https://developers.facebook.com/docs/marketing-api/conversions-api/guides/gtm-server-side/
   * @returns
   */
  protected getGenerateEventId = (): string => {
    try {
      const { google_tag_manager }: any = window;
      if (!google_tag_manager) return '';
      const gtmData = google_tag_manager['GTM-K37W8ZJ'].dataLayer.get('gtm');
      const gtmStart = gtmData?.start ?? '';
      const gtmUniqueEventId = gtmData?.uniqueEventId ?? '';

      return gtmStart + '.' + gtmUniqueEventId;
    } catch (err) {
      return '';
    }
  };

  /**
   * i-mobile Server-to-server PostBack
   * @param event event name
   * @param rid session id
   * @returns
   */
  protected sendToIMobile(event: Event, rid: string | null = null): void {
    if (!rid) return;

    switch (event) {
      case Event.JOIN:
        fetch(`/imobile/signUp/api/ad_conv.ashx?sid=33718&rid=${rid}`);
        break;
      case Event.PURCHACE_COMPLETE_MODAL:
        fetch(
          `/imobile/purchase/conversionvalue/put?rid=${rid}&sid=33718&amt=1&cls=cv2`,
        );
        break;
    }
  }

  /**
   * 페이스북 전환 Api
   * 활성화) 23.11.15 ~ (일본만 사용중)
   * @param event event name
   * @param params custom parameter
   * @param accessToken facebook conversion api accessToken
   * @param id facebook meta id
   */
  protected sendToFbca(param: {
    event: Event;
    params: any;
    accessToken: string;
    id: number;
    eventId: string;
  }): void {
    const { event, params, accessToken, id, eventId = '' } = param;
    // 사용자 IP 주소 가져오는 함수
    const getUserIp = async () => {
      let userIp = '';
      try {
        userIp = await fetch('https://api64.ipify.org/?format=json')
          .then(res => res.json())
          .then(data => {
            if (!data) throw new Error('exception Fbca data');
            return data.ip;
          });
      } catch (e) {
        console.error('fbca error', e);
      }

      return userIp;
    };

    // 페이스북 서버로 전송
    const sendToFB = async (eventName: string, customData: any = {}) => {
      const userIp = await getUserIp();

      const requestConfig: any = {
        method: 'POST',
        params: {
          access_token: accessToken,
        },
        url: `https://graph.facebook.com/v14.0/${id}/events`,
        data: {
          data: [
            {
              event_name: eventName,
              event_time: Math.floor(Date.now() / 1000),
              event_id: eventId,
              action_source: 'website',
              user_data: {
                fbp: Cookies.get('_fbp'),
                fbc: Cookies.get('_fbc'),
                em: createHash('sha256')
                  .update(TypeCheck.itemsByPath(params, 'user.id') ?? '')
                  .digest('hex'),
                client_ip_address: userIp,
                client_user_agent:
                  typeof window !== undefined ? window.navigator.userAgent : '',
              },
              custom_data: customData,
            },
          ],
        },
      };

      axios.request(requestConfig);
    };

    switch (event) {
      case Event.LOGIN: // 로그인
        sendToFB('login');
        break;

      case Event.PURCHASE_BUTTON_CLICK: // 결제 버튼 클릭
        sendToFB('InitiateCheckout');
        break;

      case Event.JOIN_BUTTON_CLICK: // 가입 버튼 클릭
        sendToFB('CompleteRegistration', {
          value: 1.0,
          status: 'CompleteRegistration',
        });
        break;

      case Event.JOIN: // 가입완료
        sendToFB('signUp');
        break;

      case Event.PURCHASE:
      case Event.PURCHACE_COMPLETE_MODAL: // 결제완료
        sendToFB('Purchase', {
          currency:
            TypeCheck.itemsByPath(params, 'ecommerce.purchase.currency') ??
            'USD',
          value:
            TypeCheck.itemsByPath(params, 'ecommerce.purchase.value') ?? '1',
          order_id:
            TypeCheck.itemsByPath(
              params,
              'ecommerce.purchase.transaction_id',
            ) ?? '1',
        });

        sendToFB('buyEvent', {
          payCount: TypeCheck.itemsByPath(params, 'pay_cnt') ?? 1,
        });
        break;

      case Event.VIEW_EPISODELIST: // 회차리스트 열람
        sendToFB('readComic', {
          comic: TypeCheck.itemsByPath(params, 'comic_id') ?? 0,
        });
        break;

      case Event.VIEW_EPISODE: // 뷰어 열람
        sendToFB('readEpisode', {
          episode: TypeCheck.itemsByPath(params, 'episode_idx') ?? 0,
        });
        break;

      case Event.PAGEVIEW: // 페이지뷰
        sendToFB('PageView');
        break;
    }
  }
}
