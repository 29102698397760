import {
  DiscountCouponType,
  LastPaymentMethodType,
  PaymentContext,
  PgType,
  ProductItemType,
} from '../context/PaymentContext';
import { TopcoStorage, UserDefault, UserSession } from '@src/lib';

import AppManager from '@src/lib/app/AppManager';
import { UserInfoType } from '@src/lib/user/types';
import _ from 'lodash';

import { ProductGroupType } from '../data/_constants';
import { convertUserBanner } from '@src/middleware/model';
import ApiPayment from '@src/lib/commonLib/api/client/V1/ApiPayment';
import { TypeCheck } from '@src/lib/commonLib/TypeCheck';

const enum MATURE_TYPE {
  NON_ADULT = 1, // 비성인
  ADULT = 2, // 성인
  BOTH = 3, // 둘다 노출
}

const enum DISPLAY_TYPE {
  HIDE = 0, // 비노출
  DISPLAY = 1, // 노출
  DEV = 2, // 개발자모드
}

export namespace TopcoPaymentRequestApi {
  export const productList = async (): Promise<any> => {
    try {
      const userInfo = UserSession.getUserInfo();
      return new ApiPayment(UserDefault.getUserSession())
        .getProducts(AppManager.getInstance().isStoreApp() ? 1 : 0)
        .then((res: any) => {
          const { data } = res.data;
          return new TopcoPayment(userInfo, data).getData();
        });
    } catch (e) {
      throw new Error('exception productList');
    }
  };
}

export class TopcoPayment {
  paymentContext: PaymentContext;

  constructor(userInfo: UserInfoType, response: any) {
    const { products } = response;

    const itemKeys: string[] = Object.keys(products);

    let pIndex = 0;

    // paymetListTransformObject 의 경우 동적으로 받아옴 키값이 탄력적으로 증감하기 때문에 주의 할 것
    const productList: Array<ProductItemType> = [];

    itemKeys.forEach((key: string, index: number) => {
      const info = TypeCheck.itemsByPath(products[key], 'info');
      const transformObject = _.transform(
        products[key].products,
        (result: any, value, k) => {
          result.push({
            ...value,
            id: pIndex++,
            groupString: `${key}`,
            info,
          });
        },
        [],
      );

      productList.push(...transformObject);
    });
    // 기본 상품 지정 (normal 상품 기준 )
    const filteredListDefaultProduct = _.filter(productList, {
      defaultProduct: 1,
    });

    // membership 상품 기본 상품 지정
    const filteredListMembershipProduct = _.filter(productList, {
      groupString: ProductGroupType.membership,
      defaultProduct: 1,
    });

    const defaultProduct =
      filteredListMembershipProduct.length > 0
        ? filteredListMembershipProduct
        : filteredListDefaultProduct;

    // goods
    const discountCoupon: DiscountCouponType.DiscountCouponType[] =
      TypeCheck.itemsByPath(response, 'goodsDiscountCoupon') ?? [];

    // user group code
    const userGroupCode =
      TypeCheck.itemsByPath(response, 'data.userGroupCode') ?? '';

    /**
     * 노출/비노출/개발자모드, hostname, order값 구분해서 pgList data setting
     */

    // 결제 수단
    const pgGroups: PaymentContext['pgGroups'] = {};
    Object.entries(response.method).forEach(([key, group]) => {
      pgGroups[key] = this.getPgGroup(group);
    });

    /**
     * => 마지막 결제 수단
     * 예외처리 여기서 하기 (관리자-유저 pg, method 다른 것)
     */
    const lastPaymentMethod = _.transform(
      response.latestPaymentMethod,
      (result: LastPaymentMethodType[], c) => {
        result.push(c);
      },
      [],
    );

    this.paymentContext = {
      token: userInfo.token,
      loginInfo: userInfo,
      pgGroups,
      topcoPayment: this,
      userBanner: _.has(response, 'userBanner')
        ? convertUserBanner(response.userBanner)
        : null,
      productList,
      discountCoupon,
      userGroupCode,
      selectPgGroup: [],
      defaultProduct: defaultProduct.length > 0 ? defaultProduct[0] : null,
      lastPaymentMethod: this.convertLastPaymentMethod(
        response.latestPaymentMethod,
      ),
    };
  }

  getData(): PaymentContext {
    return this.paymentContext;
  }

  /**
   * ANCHOR: 마지막 결제 수단
   * 예외처리 여기서 하기 (관리자-유저 pg, method 다른 것)
   */
  private convertLastPaymentMethod = (data: any): LastPaymentMethodType[] => {
    const list = _.transform(
      data,
      (result: LastPaymentMethodType[], c) => {
        result.push(c);
      },
      [],
    );
    return list;
  };

  getGroupByFirstTimeTen(): Array<ProductItemType> {
    return _.filter(this.paymentContext.productList, {
      groupString: ProductGroupType.normal,
    });
  }

  getGroupByOneDollar(): Array<ProductItemType> {
    return _.filter(this.paymentContext.productList, {
      groupString: 'ONE_DOLLAR_AUTO_PAYMENT',
    });
  }

  getGroupByNormal(): Array<ProductItemType> {
    return _.filter(this.paymentContext.productList, {
      groupString: ProductGroupType.normal,
    });
  }

  getGroupByMembership(): Array<ProductItemType> {
    return _.filter(this.paymentContext.productList, {
      groupString: ProductGroupType.membership,
    });
  }

  getGroupByAutoPayment(): Array<ProductItemType> {
    return _.filter(this.paymentContext.productList, {
      groupString: ProductGroupType.autopayment,
    });
  }

  getGroupByRefill(): Array<ProductItemType> {
    return _.filter(this.paymentContext.productList, {
      groupString: ProductGroupType.refill,
    });
  }

  /**
   * 결제 상품 그룹 획득
   * @param data
   * @returns
   */
  private getPgGroup = (data: any): PgType.GroupItemType[] => {
    if (!data) return [];

    return _.transform(
      data,
      (result: PgType.GroupItemType[], c) => {
        result.push({
          title: c.name ?? '',
          pgList: this.getPgList(c.methods),
        });
      },
      [],
    );
  };

  private getPgList = (paymethodData: any) => {
    return _.transform(
      paymethodData,
      (result: PgType.MethodItemType[], payMethodItem) => {
        if (payMethodItem.display === 0) return;

        let viewPgItem = null;

        if (payMethodItem.display === DISPLAY_TYPE.DEV) {
          // TODO : 로컬스토리지에 키가 있는지 없는지
          const currentMethod = payMethodItem.method
            ? `_${payMethodItem.method}`
            : '';
          const currentPayMethod = TopcoStorage.getItem(
            payMethodItem.pg + currentMethod,
          );

          if (currentPayMethod === undefined || currentPayMethod === null) {
            return;
          }
        }

        viewPgItem = payMethodItem;

        result.push(viewPgItem);
      },
      [],
    );
  };
}
