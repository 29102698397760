import { AxiosInstanceType, TopcoAxiosInstance } from "../topcoAxiosInstance";

interface NewsFeedLikeUpdateType {
  newsFeedId: number;
}

export default class HelpApi extends TopcoAxiosInstance {
  private GET_NEWSFEED_LIST = "/api/v1/newsFeed/list";

  private UPDATE_NEWSFEED_LIKE_STATE = "/api/v1/newsFeed";

  constructor(initValue: AxiosInstanceType) {
    super(initValue);
  }

  /**
   * newsFeed 획득 api
   * @param page
   * @returns
   */
  public getNewsFeed = (page: number) => {
    return this.requst<null>({
      url: `${this.GET_NEWSFEED_LIST}/${page}`,
    });
  };

  /**
   *
   * @param newsFeedId
   * @param isLikeUp: true이면 좋아요 false이면 좋아요 제거
   */
  public updateNewsfeedLikeState = (newsFeedId: number, isLikeUp: boolean) => {
    const likeState = isLikeUp ? "setLike" : "delLike";

    const data: NewsFeedLikeUpdateType = {
      newsFeedId,
    };

    return this.requst<NewsFeedLikeUpdateType>({
      url: `${this.UPDATE_NEWSFEED_LIKE_STATE}/${likeState}`,
      data,
    });
  };
}
