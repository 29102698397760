import { TypeCheck } from '@src/lib/commonLib/TypeCheck';
import { AxiosInstanceType, TopcoAxiosInstance } from '../topcoAxiosInstance';

export namespace PaymentForPaypal {
  /**
   * paypal 결제 완료 요청 보낼때 사용되는 VM
   */
  export interface PaymentConfirmType {
    accessToken: string;
    pg: string;
    orderId: string;
    referenceId: string;
    userId: string;
    method?: string;
  }
  export interface GooglePaymentConfirmType {
    accessToken: string;
    orderId: string;
    paymentToken: string;
    method?: string;
  }
  /**
   * paypal 결제시 실제 요청모델
   */
  export interface PaymentConfirmRequest {
    pg: string;
    orderId: string;
    referenceId: string;
    userId: string;
    method?: string;
  }
}

interface Description {
  discountPrice?: string;
  minimumPrice: string;
  currencyCode: string;
}
export interface CouponItem {
  goodsId: number;
  goodsType: string;
  name: string;
  availableAt: string;
  expiredAt: string;
  quantity: number;
  description: Description;
  idx: string;
  checked: boolean;
}

/* -------------------------------------------------------------------------- */

export type CoinInjectionType = {
  result: any;
  orderId: any;
  tid: any;
};

export interface APIProductRequest {
  productId: number;
  accessToken: string;
}

export interface APIProductLists {
  accessToken: string;
}

/* -------------------------------------------------------------------------- */

export namespace PaymentPrepareRequest {
  /**
   * 결제 요청 전 필수정보 타입
   */
  export interface PaymentPrepareType {
    accessToken: string;
    productId: number;
    pg: string;
    latestLocation: string;
    viewType: string;
    methodPayment?: string;
    eximbayData?: object; // 엑심베이 request obj
    CouponItem?: any | null; // 결제 할인 쿠폰
    goods?: PaymentGoodsType | null; // CouponItem 존재시 goods 로 전환됨
    paymentMethodId?: number | string | null;
    token?: string; // paymentwall 자동충전 테스트
    fingerprint?: string; // paymentwall 자동충전 테스트
    isUseLastPayMethod?: boolean; // 마지막 결제 수단을 클릭해서 prepare 한건지 여부
    payMethod?: string; // 결제 요청용 (paymentWall)
    paymethod?: string; // 결제 요청용 (eximbay)
    loginId?: string; // wechat 결제 요청용
  }

  /**
   * 결제 할인 쿠폰 타입
   */
  export interface PaymentGoodsType {
    goodsId: string;
    goodsType: string;
  }
}

/**
 * 위쳇 페이 interval 체크 모델
 */
export interface WechatIntervalCheckRequest {
  pg: string;
  orderId: string;
}

export interface OrderConfirmBoku {
  accessToken: string;
  orderId: string;
  pg: string;
  country: string;
}

export type REFILL_ORDER = Pick<
  PaymentForPaypal.PaymentConfirmType,
  'orderId' | 'accessToken'
>;

export default class ApiPayment extends TopcoAxiosInstance {
  private PRODUCT_LIST = '/api/v1/product/lists';

  private PAYMENT_PREPARE = '/payment/prepare';

  private PAYMENT_CONFIRM = '/payment/confirm';

  private GET_COUNT_BY_USERID = '/payment/countByUserId';

  private ORDER_CONFIRM_BOKU = '/orderConfirm/boku';

  private PAYMENT_UPDATE_ORDER_ID = '/payment/update/';

  private PAYMENT_PREPARE_RECURRING = '/payment/prepare/recurring';

  private GOOGLE_PAYMENT_CONFIRM = '/payment/confirm/gmo/googlePay';

  private PAYMENT_REFILL = '/payment/reFill';

  private PAYMENT_REFILL_METHOD_MODIFY = '/payment/refill/method/modify';

  private MODAL_CHECK = '/modal/log';

  private WECHAT_INTERVAL_CHECK = '/payment/intervalCheck';

  constructor(initValue: AxiosInstanceType) {
    super(initValue);
  }

  /**
   * @deprecated
   * @param request
   * @returns
   */
  public orderConfirmBoku(request: OrderConfirmBoku): Promise<any> {
    return this.requst<OrderConfirmBoku>({
      method: 'post',
      url: `${this.ORDER_CONFIRM_BOKU}`,
      data: request,
    });
  }

  /**
   * 결제 상품 리스트 api
   * @param isNative: nativeApp 구분 (storeApp 상품 노출 유무)
   * @returns
   */
  public getProducts(isNative: number = 0) {
    return this.requst<null>({
      url: this.PRODUCT_LIST,
      headers: {
        'is-Native': isNative,
      },
    });
  }
  /**
   * payment orderid 발급
   * @param request
   * @param callback
   */
  public paymentPrepare = (
    request: PaymentPrepareRequest.PaymentPrepareType,
  ) => {
    let data: PaymentPrepareRequest.PaymentPrepareType = request;

    // 결제할인 쿠폰 적용
    if (request.CouponItem) {
      // CouponItem => goods  전환 처리
      data = {
        ...data,
        goods: {
          goodsId: request.CouponItem.goodsId.toString(),
          goodsType: request.CouponItem.goodsType,
        },
      };
    }

    // 요청 전 CouponItem 제거
    delete data.CouponItem;

    return this.requst<PaymentPrepareRequest.PaymentPrepareType>({
      method: 'post',
      url: this.PAYMENT_PREPARE,
      data,
    });
  };

  /**
   * @param request
   * @param callback
   */
  public paymentPrepareRefill = (
    request: PaymentPrepareRequest.PaymentPrepareType,
  ) => {
    let data: PaymentPrepareRequest.PaymentPrepareType = request;

    return this.requst<PaymentPrepareRequest.PaymentPrepareType>({
      method: 'post',
      url: `${this.PAYMENT_PREPARE}/refill`,
      data,
    });
  };

  /**
   * @param request
   * @param callback
   */
  public paymentRefillCancel = (request: REFILL_ORDER) => {
    let data: REFILL_ORDER = request;

    return this.requst<REFILL_ORDER>({
      method: 'post',
      url: `${this.PAYMENT_REFILL}/cancel`,
      data,
    });
  };

  /** 리필 결제 방법 수정 */
  public paymentRefillMethodModify = (
    request: PaymentPrepareRequest.PaymentPrepareType,
  ) => {
    let data: PaymentPrepareRequest.PaymentPrepareType = request;

    return this.requst<PaymentPrepareRequest.PaymentPrepareType>({
      method: 'post',
      url: `${this.PAYMENT_REFILL_METHOD_MODIFY}`,
      data,
    });
  };

  /**
   * @param request
   * @param callback
   */
  public paymentRefillRetry = (request: REFILL_ORDER) => {
    let data: REFILL_ORDER = request;

    return this.requst<REFILL_ORDER>({
      method: 'post',
      url: `${this.PAYMENT_REFILL}/retry`,
      data,
    });
  };

  /**
   *
   * @param request
   * @param callback
   */
  public paymentPrepareRecurring = (
    request: PaymentPrepareRequest.PaymentPrepareType,
  ) => {
    let data: PaymentPrepareRequest.PaymentPrepareType = request;

    return this.requst<PaymentPrepareRequest.PaymentPrepareType>({
      method: 'post',
      url: `${this.PAYMENT_PREPARE_RECURRING}`,
      data,
    });
  };

  /**
   * @deprecated
   * Paypal 결제 완료 요청
   * @param request
   * @param callback
   */
  public paymentConfirm = (request: PaymentForPaypal.PaymentConfirmType) => {
    const data: PaymentForPaypal.PaymentConfirmRequest = {
      pg: request.pg,
      orderId: request.orderId,
      referenceId: request.referenceId,
      userId: request.userId,
    };

    // recurring의 경우 method 추가됨
    if (TypeCheck.itemsByPath(request, 'method')) {
      data['method'] = request['method'];
    }

    const headers: any = {
      token: request.accessToken,
    };

    if (request.accessToken === null) {
      delete headers.token;
    }

    return this.requst<PaymentForPaypal.PaymentConfirmRequest>({
      method: 'post',
      url: `${this.PAYMENT_CONFIRM}`,
      headers: headers,
      data,
    });
  };

  /**
   * Paypal 결제 완료 요청
   * @param request
   * @param callback
   */
  public googlePaymentConfirm = (
    request: PaymentForPaypal.GooglePaymentConfirmType,
  ) => {
    const data: Omit<PaymentForPaypal.GooglePaymentConfirmType, 'accessToken'> =
      {
        orderId: request.orderId,
        paymentToken: request.paymentToken,
      };

    const headers: any = {
      token: request.accessToken,
    };

    if (request.accessToken === null) {
      delete headers.token;
    }

    return this.requst<
      Omit<PaymentForPaypal.GooglePaymentConfirmType, 'accessToken'>
    >({
      method: 'post',
      url: `${this.GOOGLE_PAYMENT_CONFIRM}`,
      headers: headers,
      data,
    });
  };

  /**
   * 위쳇 요청시 payment prepare 요청
   * @param request
   * @param callback
   */
  public paymentPrepareFroWeChat = (
    request: PaymentPrepareRequest.PaymentPrepareType,
  ) => {
    let data: PaymentPrepareRequest.PaymentPrepareType = request;

    // 결제할인 쿠폰 있을 경우 goods 추가
    if (request.CouponItem) {
      // CouponItem => goods  전환 처리
      data = {
        ...data,
        goods: {
          goodsId: request.CouponItem.goodsId.toString(),
          goodsType: request.CouponItem.goodsType,
        },
      };
    }
    // wechat 무조건 소문자 지정
    data = {
      ...data,
      pg: data.pg.toLowerCase(),
    };

    // 요청 전 CouponItem 제거
    delete data.CouponItem;

    return this.requst<PaymentPrepareRequest.PaymentPrepareType>({
      method: 'post',
      url: `${this.PAYMENT_PREPARE}`,
      data,
    });
  };

  /**
   * 결제 횟수 획득
   */
  public getCountByUserId = () => {
    return this.requst<null>({
      url: this.GET_COUNT_BY_USERID,
    });
  };

  /**
   * @deprecated API 확장용 (실사용 X)
   * 코인 지급 요청 복원 (Axios 샘플 참조용!!!)
   * @param result
   * @param orderId
   * @param tid
   * @param accessToken
   * @returns
   */
  // request 타입
  public coinInjection = async (
    result: string,
    orderId: string,
    tid: string,
  ) => {
    const data: CoinInjectionType = {
      result,
      orderId,
      tid,
    };

    const request = this.requst<CoinInjectionType>({
      method: 'post',
      url: `payment/result`,
      data,
    });

    return request;
  };

  /**
   * 결제 정보 업데이트
   * @param orderId paypal value
   * @returns
   */
  public paymentUpdateOrderId = (orderId: string, papalValue: string) => {
    const requestBody: any = {
      params: {
        tId: papalValue,
      },
    };
    return this.requst<any>({
      method: 'post',
      url: `${this.PAYMENT_UPDATE_ORDER_ID}${orderId}`,
      data: requestBody,
    });
  };

  /**
   * @deprecated
   * 데스크탑에서 구매확인 interval
   * @param orderId 위쳇 주문 번호
   * @returns
   */
  public wechatIntervalCheckForDesktop = (orderId: string) => {
    const requestBody: WechatIntervalCheckRequest = {
      pg: 'wechat',
      orderId,
    };
    return this.requst<WechatIntervalCheckRequest>({
      method: 'post',
      url: this.WECHAT_INTERVAL_CHECK,
      data: requestBody,
    });
  };

  // TODO: 사용안함 확장을 위해 남겨둠
  // useProductId = async (productId, accessToken, callback) => {
  //   const PRODUCT_ID = '/api/v1/product';
  //   try {
  //     const response = await axios.request({
  //       method: 'get',
  //       url: `${PRODUCT_ID}/${productId}`,
  //       responseType: 'json',
  //       headers: {
  //         'x-api-key': `${process.env.REACT_APP_API_KEY}`,
  //         language: 'en',
  //         token: accessToken,
  //       },
  //     });

  //     callback(response.data);
  //   } catch (error) {
  //     callback(error);
  //   }
  // };

  // TODO: 사용안함 확장을 위해 남겨둠
  // getProductId = createAsyncThunk(
  //   PRODUCT_ID,
  //   async (request: APIProductRequest, thunkAPI) => {
  //     try {
  //       const response = await axios.request({
  //         method: 'get',
  //         url: `${PRODUCT_ID}/${request.productId}`,
  //         responseType: 'json',
  //         headers: {
  //           token: request.accessToken,
  //           language: 'en',
  //         },
  //       });

  //       return response.data;
  //     } catch (error) {
  //       thunkAPI.rejectWithValue(error.response.data);
  //     }
  //   },
  // );
}
