import { AxiosInstanceType, TopcoAxiosInstance } from "../topcoAxiosInstance";

export interface EventThemeType {
  id: number;
}

export default class ApiEventTheme extends TopcoAxiosInstance {
  private GET_EVENT_THEME = "/api/v1/eventTheme";

  constructor(initValue: AxiosInstanceType) {
    super(initValue);
  }

  public getEventTheme = (id: number) => {
    return this.requst<EventThemeType>({
      url: `${this.GET_EVENT_THEME}/${id}`,
    });
  };
}
