import { _days } from '@src/lib/commonLib/_day';
import {
  ApiNetworkCallbackType,
  ApiRequestPropsType,
  AxiosInstanceType,
} from '.';
import axios, { AxiosInstance } from 'axios';
import { Hash } from '@src/lib/commonLib/Hash';
import { TypeCheck } from '@src/lib/commonLib/TypeCheck';

export abstract class TopcoAxiosInstance {
  protected readonly instance: AxiosInstance;

  public constructor(initValue: AxiosInstanceType) {
    const { headers, baseURL, apiInteration } = initValue;

    const timestamp = _days.nowUtcTimestamp();
    let apiKey = headers['x-api-key'];

    if (apiInteration) {
      apiKey = Hash.convertApiKey(timestamp, headers.deviceId, apiInteration);
    }

    /**
     * Type 'HeadersType' is not assignable to type 'AxiosRequestHeaders'.
     * Index signature for type 'string' is missing in type 'HeadersType'.
     * axios.defaults.headers.common = topcoHeaders; 선언 시
     * typeError 뜨는 관계로 any로 임시 처리
     */
    const topcoHeaders: any = {
      'x-api-key': apiKey,
      version: headers.version,
      deviceId: headers.deviceId,
      partnerCode: headers.partnerCode,
      ua: headers.ua,
      token: headers.token,
      language: headers.language,
      'user-id': headers['user-id'],
      'x-origin': headers['x-origin'],
      timestamp,
      timezone: _days.getTimezone(),
      pathname: headers.pathname,
      'package-name': headers['package-name'], // 앱전용
      'visitor-id': headers['visitor-id'],
      'local-datetime': _days.timezone('now').format('YYYY-MM-DD HH:mm:ss'), // 2022/12/9 추가
      'therok-key': headers['therok-key'],
    };

    if (!topcoHeaders.token) {
      delete topcoHeaders.token;
    }

    if (!topcoHeaders.partnerCode) {
      delete topcoHeaders.partnerCode;
    }

    if (!topcoHeaders['visitor-id']) {
      delete topcoHeaders['visitor-id'];
    }

    if (!topcoHeaders['therok-key']) {
      delete topcoHeaders['therok-key'];
    }

    if (!topcoHeaders.ua) {
      delete topcoHeaders.ua;
    }

    axios.defaults.baseURL = baseURL;
    axios.defaults.responseType = 'json';
    axios.defaults.headers.common = topcoHeaders;
    axios.defaults.timeout = 20 * 1000;
    this.instance = axios;
  }

  /**
   * 사용자 로그아웃 처리, 백엔드 api 네트워크 콜백 함수
   * @param cb
   */
  public defaultInterceptor = (cb: ApiNetworkCallbackType) => {
    axios.interceptors.request.use(config => {
      config.meta = { requestStartedAt: new Date().getTime() };
      return config;
    });

    axios.interceptors.response.use(
      response => {
        const locale = TypeCheck.itemsByPath(response, 'headers.locale');
        const version = TypeCheck.itemsByPath(response, 'headers.version');
        const userId: string | null | undefined = TypeCheck.itemsByPath(
          response,
          'headers.user-id',
        );
        const preAuthToken: string | null | undefined = TypeCheck.itemsByPath(
          response,
          'headers.token',
        );

        if (userId) {
          cb.callbackUserId(userId);
        }

        if (cb.callbackPreAuthToken && preAuthToken) {
          cb.callbackPreAuthToken(preAuthToken, userId);
        }

        // 서버 버전 전달
        cb.callbackServerVersion(version);

        if (locale) {
          // 한국의 경우 성인 체크버튼 비노출 (정책 적용) 0 비대상 1 대상
          const result = locale.toLowerCase() === 'kr' ? '0' : '1';
          cb.callbackServerLocale(result, locale.toLowerCase());
        }
        const responseMeta = response.config.meta
          ? response.config.meta.requestStartedAt
          : 0;
        cb.callbackResponseTime(
          response.config.url ?? '',
          responseMeta,
          new Date().getTime(),
        );
        return Promise.resolve(response);
      },
      error => {
        // 접근 에러 (성인인증, ipblock)
        if (error.response?.status === 461) {
          const { action } = error.response.data;
          cb.onErrorAccessDenied(action);
          return;
        }

        // logout 처리
        if (error.response?.status === 463) {
          cb.onErrorLogout_463();
        }

        // 유출자 경고 페이지 이동
        if (error.response?.status === 465) {
          cb.onErrorBlockUser_465();
        }

        if (error.response?.status === 466) {
          cb.onErrorFingerPrintId_466 && cb.onErrorFingerPrintId_466();
        }

        if (error.response?.status === 470) {
          cb.onErrorRedirect_470();
        }

        // 유출자 & 의심자 회차리스트 접근
        if (error.response?.status === 511) {
          cb.onErrorDenied_511();
        }

        if (error.response?.status === 503) {
          cb.onErrorShutDown_503();
        }

        if (error.response?.status === 563) {
          const endDateAt =
            TypeCheck.itemsByPath(error.response, 'headers.x-retry-after') ??
            '';

          cb.onErrorServerCheck_563 && cb.onErrorServerCheck_563(endDateAt);
        }
        return Promise.reject(error);
      },
    );
  };

  /**
   * 각 api별 데이터 추가용도
   * @param props
   * @returns
   */
  protected requst = <T extends unknown>(props: ApiRequestPropsType<T>) => {
    const { headers, meta, method = 'get', url, data, params } = props;
    if (!!headers && !headers['is-Native']) delete headers['is-Native'];
    if (!!headers && headers.offset === undefined) delete headers.offset;

    const axiosConfig: AxiosInstance | any = {
      headers,
      method,
      url,
      data,
      params,
      meta,
    };

    return axios.request(axiosConfig);
  };
}
