import * as FingerprintJS from '@fingerprintjs/fingerprintjs-pro';
import { UserDefault, UserSession } from '../user';
import { ApiUser } from '@src/lib/commonLib/api/client/V1';

export namespace VisitorUtils {
  const isExistCheckVisitorId = (): Promise<string> => {
    const fId = UserSession.getFingerPrintId();
    const hostName = window.location.hostname;
    const accessDomain =
      hostName.includes('localhost') || hostName.includes('daycomics.com');
    if (!accessDomain) return Promise.resolve('');
    if (fId) {
      return Promise.resolve(fId);
    }
    return Promise.resolve('');
  };

  export const fetchVisitorId = async (
    forceFetch: boolean = false,
  ): Promise<string> => {
    if (!forceFetch) {
      await isExistCheckVisitorId();
    }
    try {
      const fpPromise = FingerprintJS.load({
        apiKey: `${process.env.FINGER_PRINT_KEY}`,
        endpoint: 'https://fpapi.daycomics.com',
        scriptUrlPattern: [
          'https://fpapi.daycomics.com/web/v<version>/<apiKey>/loader_v<loaderVersion>.js',
        ],
      });

      const fp = await fpPromise;
      const r = await fp.get();

      if (!r || !r.visitorId) {
        return Promise.resolve('');
      }
      UserSession.isNeedValidateVisitorId.setter();
      UserSession.setFingerPrintId(r.visitorId);
      return Promise.resolve(r.visitorId);
    } catch (e) {
      console.warn(e);
      return Promise.resolve('');
    }
  };

  export const validateVisitorId = async (): Promise<string> => {
    if (
      (!UserSession.isRefreshVisitorId.check() ||
        UserSession.isNeedValidateVisitorId.getter()) &&
      UserSession.getFingerPrintId()
    ) {
      const res = await new ApiUser(
        UserDefault.getUserSession(),
      ).checkValidatedVisitorId();
      return res.data?.data?.expiredAt;
    } else if (!UserSession.getFingerPrintId()) {
      return Promise.resolve('');
    }
    return Promise.reject();
  };

  // site check 유효성 검사 용
  export const testValidateVisitorId = async (): Promise<string> => {
    if (UserSession.getFingerPrintId()) {
      const res = await new ApiUser(
        UserDefault.getUserSession(),
      ).checkValidatedVisitorId();
      return res.data?.data?.expiredAt;
    } else if (!UserSession.getFingerPrintId()) {
      return Promise.resolve('');
    }
    return Promise.reject();
  };
}
