import { AxiosInstanceType, TopcoAxiosInstance } from "../topcoAxiosInstance";

export interface RequestComicFollow {
  comicIds: number[];
}

export interface RequestFollowingList {
  page: number;
  limit: number;
}

export default class ApiEpisodeAutoPurchase extends TopcoAxiosInstance {
  private SET_COMIC_FOLLOW = "/api/v1/episodeAutoPurchase/setComicFollow";
  private DEL_COMIC_FOLLOW = "/api/v1/episodeAutoPurchase/deleteComicFollow";
  private FOLLOWING_LIST = "/api/v1/episodeAutoPurchase/followingList";

  constructor(initValue: AxiosInstanceType) {
    super(initValue);
  }

  /**
   * 만화 구독 추가
   * @param request
   * @returns
   */
  public setComicFollow(request: RequestComicFollow): Promise<any> {
    const { comicIds } = request;

    return this.requst<RequestComicFollow>({
      method: "post",
      url: `${this.SET_COMIC_FOLLOW}`,
      data: {
        comicIds,
      },
    });
  }

  /**
   * 만화 구독 삭제
   * @param request
   * @returns
   */
  public deleteComicFollow(request: RequestComicFollow): Promise<any> {
    const { comicIds } = request;

    return this.requst<RequestComicFollow>({
      method: "post",
      url: `${this.DEL_COMIC_FOLLOW}`,
      data: {
        comicIds,
      },
    });
  }

  /**
   * 만화 구독리스트
   * @param request
   * @returns
   */
  public followingList(request: RequestFollowingList): Promise<any> {
    const { page, limit } = request;

    return this.requst<RequestFollowingList>({
      method: "post",
      url: `${this.FOLLOWING_LIST}`,
      data: {
        page,
        limit,
      },
    });
  }
}
