import { AxiosInstanceType, TopcoAxiosInstance } from '../topcoAxiosInstance';

export interface LoginRequestType {
  userId: string;
  password: string;
  auth: number;
  is17: boolean;
  deviceId: string;
  isAlreadyMature?: number; // 사전인증 체크 TODO:라이브후 수정
  token?: string; // 사전인증토큰
  partnerCode?: string;
  cToken?: string;
}

export interface hostnameType {
  hostname: 'toptoonplus.com' | 'toptoon.jp';
}

interface TwitterOauthTokenType {
  callbackUrl: string;
}

interface MatureUpdateType {
  mature: number;
  auth?: number;
  actionType?: number;
  origin?: string;
  destination?: string;
  authEventCode?: string;
  comicId?: number;
  episodeId?: number;
}

interface LoginCountType {
  deviceId: string;
}

export default class ApiLogin extends TopcoAxiosInstance {
  private LOGIN_URL = '/auth/generateToken';

  private GET_TWITTER_OAUTH_TOKEN = '/authSns/twitterToken';

  private SNS_LOGIN_URL = '/authSns/snsToken';

  private JA_SOCIAL_LOGIN = '/authSns/jpSnsToken';

  private LOGOUT_URL = '/users/logout';

  private UPDATE_MATURE_URL = '/users/setUser';

  private UPDATE_ALREADY_MATURE = '/preAuth/setMature';

  private UPDATE_LOGIN_COUNT = '/auth/loginCount';

  constructor(initValue: AxiosInstanceType) {
    super(initValue);
  }

  /**
   * Login Api
   * @param request
   * @returns
   */
  public loginProc = (request: LoginRequestType, cToken?: string) => {
    const data: LoginRequestType = {
      userId: request.userId,
      password: request.password,
      auth: request.auth,
      is17: request.is17,
      deviceId: request.deviceId,
      partnerCode: request.partnerCode,
      isAlreadyMature: request.isAlreadyMature,
      cToken: cToken ?? '',
      token: request.token ?? '',
    };

    if (typeof data.isAlreadyMature === 'undefined') {
      delete data.isAlreadyMature;
    }

    if (data.partnerCode?.length === 0) {
      delete data.partnerCode;
    }

    return this.requst<LoginRequestType>({
      method: 'post',
      url: this.LOGIN_URL,
      data,
    });
  };

  /**
   * 일본 소셜 로그인 연동
   * @param credential
   * @returns
   */
  public jpSnsLogin = (credential: any) => {
    return this.requst<any>({
      method: 'post',
      url: this.SNS_LOGIN_URL,
      data: credential,
    });
  };

  /**
   * 소셜 로그인 api
   * @param credential
   */
  public snsLoginProc = (credential: any) => {
    return this.requst<any>({
      method: 'post',
      url: this.SNS_LOGIN_URL,
      data: credential,
    });
  };

  /**
   * twitter oauth token 획득 api
   */
  public getTwitterOauthToken = (url: string) => {
    const data: TwitterOauthTokenType = {
      callbackUrl: url,
    };

    return this.requst<TwitterOauthTokenType>({
      method: 'post',
      url: this.GET_TWITTER_OAUTH_TOKEN,
      data,
    });
  };

  /**
   * logout
   * @returns
   */
  public logoutProc = () => {
    return this.requst<null>({
      method: 'post',
      url: this.LOGOUT_URL,
    });
  };

  /**
   * 성인상태 업데이트 API
   * @param mature : view에 대한 성인 상태 변경 요청 1:성인 0:비성인
   * @param auth : 성인 인증 상태
   * @param authEventCode : authEvent 인증 유저용
   * @param comicId : authEventCode decrypt 후 검증용
   * @param episodeId : authEventCode decrypt 후 검증용
   * @constructor
   */
  public updateMatureProc = (
    mature: number,
    auth: number = 0,
    authEventCode?: string,
    comicId?: number,
    episodeId?: number,
    uuid: string = ''
  ) => {
    const data: MatureUpdateType = {
      mature,
      auth,
      authEventCode,
      comicId,
      episodeId,
    };

    if (auth === 0) {
      delete data.auth;
    }

    return this.requst<MatureUpdateType>({
      method: 'post',
      url: this.UPDATE_MATURE_URL,
      data,
    });
  };

  /**
   * 어디에서 로그인이 이루어지는지 (임시 사용)
   * @deprecated
   * @param params
   */
  public updateMatureTempProc = (params: MatureUpdateType) => {
    const data: MatureUpdateType = params;

    if (params.auth === 0) {
      delete data.auth;
    }

    if (!params.actionType) {
      delete data.actionType;
      delete data.origin;
      delete data.destination;
    }

    return this.requst<MatureUpdateType>({
      method: 'post',
      url: this.UPDATE_MATURE_URL,
      data,
    });
  };

  /**
   * 사전인증 API
   */
  public updateAlreadyMatureProc = (mature: number) => {
    return this.requst<{ mature: number }>({
      method: 'post',
      url: this.UPDATE_ALREADY_MATURE,
      data: { mature },
    });
  };

  /**
   * 로그인 카운트 집계용
   * @param deviceId
   */
  public updateLoginCount = (deviceId: string) => {
    const data: LoginCountType = {
      deviceId,
    };

    return this.requst<LoginCountType>({
      method: 'post',
      url: this.UPDATE_LOGIN_COUNT,
      data,
    });
  };
}
