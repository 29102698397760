import { AxiosInstanceType, TopcoAxiosInstance } from "../topcoAxiosInstance";

interface HashTagType {
  name: string;
  type: string;
}

interface SearchByKeywordType {
  keywords: string[];
  isEpClick: number;
  comicId?: number;
}

export default class ApiComic extends TopcoAxiosInstance {
  private GET_HOME_DATA_URL = "/api/v1/page/main";

  private GET_DAILY_DATA_URL = "/api/v1/page/daily";

  private GET_COMIC_DATA_URL = "/api/v1/page";

  private GET_COMPLETE_DATA_URL = "/api/v1/page/complete";

  private GET_ORIGINFREE_DATA_URL = "/api/v1/page/originFree";

  private GET_COMIC_BINGE_URL = "/api/v1/page/binge";

  private GET_COMIC_WAITFREE_URL = "/api/v1/page/waitfree";

  private GET_HASHTAG_COMIC_DATA_URL = "/api/v1/hashtag";

  private GET_COMIC_ALL = "api/v1/search/totalsearch";

  private GET_PREVIEW = "/api/v1/page/preview";

  private UPDATE_SEARCH_BY_KEYWORD = "/statistics/searchByKeyword";

  constructor(initValue: AxiosInstanceType) {
    super(initValue);
  }

  /**
   * 메인 page api
   * @param request
   * @returns
   */

  public getHomeData = () => {
    return this.requst<null>({
      url: this.GET_HOME_DATA_URL,
    });
  };

  /**
   * 연재 page api
   * @param request
   * @param week
   * @returns
   */
  public getDailyData = (week: string) => {
    const param = week === "TOP" ? "popular" : week;

    return this.requst<null>({
      url: `${this.GET_DAILY_DATA_URL}/${param}`,
    });
  };

  /**
   * 완결(complete) page api
   * @param key : 최신완결 / 최고매출 (없으면 일반 완결)
   * @returns : comicList
   */
  public getCompleteData = (key: string = "") => {
    let url = this.GET_COMPLETE_DATA_URL;

    if (key.length > 0) url += `/${key}`;

    return this.requst<null>({
      url,
    });
  };

  /**
   * 독점무료(OriginFree) page api
   * @returns : comicList
   */
  public getOriginFreeData = () => {
    return this.requst<null>({
      url: `${this.GET_ORIGINFREE_DATA_URL}`,
    });
  };

  /**
   * main comic list page api
   * @param category : complete/new/ranking
   * @returns : comicList
   */
  public getComicData = (category: string) => {
    return this.requst<null>({
      url: `${this.GET_COMIC_DATA_URL}/${category}`,
    });
  };

  /**
   * hashTag 상세페이지 api
   * @param request
   * @param name
   * @returns
   */
  public getHashTagComicData = (name: string, type: string) => {
    const data: HashTagType = {
      name,
      type,
    };
    return this.requst<HashTagType>({
      method: "post",
      url: this.GET_HASHTAG_COMIC_DATA_URL,
      data,
    });
  };

  /**
   * 검색용 comic data
   * @deprecated getTotalSearch 사용
   * @returns
   */
  public getComicAllData = () => {
    return this.requst<null>({
      url: this.GET_COMIC_ALL,
    });
  };

  /**
   * 검색용 comic data
   * @returns
   */
  public getTotalSearch = () => {
    return this.requst<null>({
      url: this.GET_COMIC_ALL,
    });
  };

  /**
   * binge page api
   * @param language
   * @returns
   */
  public getBingeData = () => {
    return this.requst<null>({
      url: this.GET_COMIC_BINGE_URL,
    });
  };

  /**
   * 기다리면 무료 api
   * @returns
   */
  public getWaitFreeData = () => {
    return this.requst<null>({
      url: this.GET_COMIC_WAITFREE_URL,
    });
  };

  /**
   * 오픈예정 작품 리스트
   * @returns
   */
  public getPreViewData = () => {
    return this.requst<null>({
      url: this.GET_PREVIEW,
    });
  };

  public updateSearchByKeywords = (
    keywords: string[],
    isEpClick: number,
    comicId?: number
  ) => {
    return this.requst<SearchByKeywordType>({
      url: this.UPDATE_SEARCH_BY_KEYWORD,
      method: "post",
      data: {
        keywords,
        comicId,
        isEpClick,
      },
    });
  };
}
