import { AxiosInstanceType, TopcoAxiosInstance } from "../topcoAxiosInstance";

interface SubscribeUpdateType {
  comicId: number;
  subscribed: number;
}

interface DeleteLibary {
  comicIdArray: number[];
}

export default class ApiUserComic extends TopcoAxiosInstance {
  private GET_GIFT_LIST = "api/v1/gift/lists";

  private GET_GIFT_LIST_V2 = "api/v2/gift/list";

  private GET_LIBRARY_LIST = "/api/v1/page/library";

  private DELETE_LIBRARY = "/users/deleteUserComicView";

  private GET_SUBSCRIBED_LIST = "/api/v1/page/subscribed";

  private DELETE_SUBSCRIBED = "/users/deleteUserSubscribed";

  private UPDATE_SUBSCRIBED = "/users/setUserComicInformation";

  constructor(initValue: AxiosInstanceType) {
    super(initValue);
  }

  // 선물함 목록 API
  public getGiftListData = () => {
    return this.requst<null>({
      method: "post",
      url: this.GET_GIFT_LIST,
    });
  };

  /**
   * 선물함 목록 API V2
   * @returns
   */
  public getGiftListDataV2 = () => {
    return this.requst<null>({
      method: "post",
      url: this.GET_GIFT_LIST_V2,
    });
  };

  /**
   * 내서재 목록 API
   * @returns
   */
  public getLirbraryListProc = () => {
    return this.requst<null>({
      url: this.GET_LIBRARY_LIST,
    });
  };

  /**
   * 구독중인 리스트 획득 api
   * @constructor
   */
  public getSubscribeListProc = () => {
    return this.requst<null>({
      url: this.GET_SUBSCRIBED_LIST,
    });
  };

  /**
   * 구독하기 요청
   * @param comicId
   * @returns
   */
  public updateSubscribedProc = (comicId: number, subscribed: number) => {
    const data: SubscribeUpdateType = {
      comicId,
      subscribed,
    };

    return this.requst<SubscribeUpdateType>({
      method: "post",
      url: this.UPDATE_SUBSCRIBED,
      data,
    });
  };

  /**
   * 내서재에서 삭제 api
   * @param comicIdArray
   * @constructor
   */
  public deleteLibrary = (comicIdArray: number[]) => {
    const data: DeleteLibary = {
      comicIdArray,
    };

    return this.requst<DeleteLibary>({
      method: "post",
      url: this.DELETE_LIBRARY,
      data,
    });
  };

  /**
   * 구독 목록 삭제 api
   * @param comicIdArray
   * @constructor
   */
  public deleteSubscribed = (comicIdArray: number[]) => {
    const data: DeleteLibary = {
      comicIdArray,
    };

    return this.requst<DeleteLibary>({
      method: "post",
      url: this.DELETE_SUBSCRIBED,
      data,
    });
  };
}
