import { ApiUser } from '@src/lib/commonLib/api/client/V1';
import { UserDefault } from '@src/lib';
import { NetworkCallback } from '@src/lib/commonLib/api/ApiManager';

const useAccount = () => {
  const findPassword = (loginId: string, callback?: NetworkCallback) => {
    return new ApiUser(UserDefault.getApiVaildateHeaders())
      .findPassword(loginId)
      .then((res: any) => {
        callback?.onComplete(res);
      })
      .catch(err => {
        callback?.onError(err);
      });
  };

  return { findPassword };
};

export default useAccount;
