import { AxiosInstanceType, TopcoAxiosInstance } from "../topcoAxiosInstance";

export interface ActionLayerLogReqType {
  actionLayerId: number;
  buttonId?: number;
}

export default class ApiLayer extends TopcoAxiosInstance {
  private LAYER_URL = "/api/v1/layer/freeComicByRanking";

  private SECRET_CLASS_REWARD_CONFIRM =
    "/api/v1/layer/confirm/secretClassReward";

  private ACTIONLAYER_LOG = "/log/actionLayerClick";

  constructor(initValue: AxiosInstanceType) {
    super(initValue);
  }

  /**
   * banner
   * @param request
   * @returns
   */
  public freeComicByRanking = () => {
    return this.requst<null>({
      url: this.LAYER_URL,
    });
  };

  /**
   * <비밀수업> 리워드 지급 API
   * - jp 에서만 사용중 (~ 23.09. 예정)
   * @param request
   * @returns
   */
  public confirmSecretClassReward = () => {
    return this.requst<null>({
      method: "post",
      url: this.SECRET_CLASS_REWARD_CONFIRM,
    });
  };

  /**
   * 액션레이어 버튼 클릭 or 닫기 로그
   * @param request
   * @returns
   */
  public updateActionLayerLog = (request: ActionLayerLogReqType) => {
    return this.requst<ActionLayerLogReqType>({
      method: "post",
      url: this.ACTIONLAYER_LOG,
      data: request,
    });
  };
}
