import dayjs, { ManipulateType } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import advanced from 'dayjs/plugin/advancedFormat';
import toObject from 'dayjs/plugin/toObject';
import duration from 'dayjs/plugin/duration';
import { convertFormat } from '../utils/timeUtils';

dayjs.extend(utc); // day.utc day.isUTC 등의 구문을 추가
dayjs.extend(timezone); // day.tz() 타임존 추가
dayjs.extend(advanced); // day.format() 의 포맷 형식을 확장
dayjs.extend(toObject); // day.toObject 날짜를 객체형식으로 반환
dayjs.extend(duration); // duration

const week = ['일', '월', '화', '수', '목', '금', '토'];

type DayCalType = 'add' | 'subtract';
export type TimeFormat = '' | 'TIME' | 'DAY' | 'MONTH';
type setTimeType = 'startAt' | 'endAt' | 'none';
type Timezone = 'KST' | 'JST' | 'EST' | 'UTC' | 'DEFAULT';
declare module 'dayjs' {
  interface Dayjs {
    formatType(a: TimeFormat): string;
  }
}
class _days {
  constructor() {}

  private static classInstance: _days;

  /**
   * @returns 기본 dayjs methods 사용을 위함
   */
  static day = dayjs;

  static timezone = (
    date: dayjs.ConfigType | 'now',
    timezone: Timezone = 'DEFAULT',
  ) => {
    const _date = date === 'now' ? dayjs() : dayjs(date);

    const resultDate = (): dayjs.Dayjs => {
      switch (timezone) {
        case 'UTC': {
          return _date.tz('Africa/Abidjan');
        }
        case 'EST': {
          return _date.tz('America/New_York');
        }
        case 'KST': {
          return _date.tz('Asia/Seoul');
        }
        case 'JST': {
          return _date.tz('Asia/Tokyo');
        }
        case 'DEFAULT':
        default: {
          return _date;
        }
      }
    };

    dayjs.prototype.formatType = function formatType(format: TimeFormat) {
      const formatTypes =
        format === 'TIME' ? 'YYYY-MM-DD HH:mm:ss' : 'YYYY-MM-DD';
      return resultDate().format(formatTypes);
    };

    return resultDate();
  };

  static getTimezone = () => {
    return dayjs.tz.guess() || 'America/New_York';
  };

  static dateAt = (
    value: number,
    type: DayCalType,
    options: {
      unit: ManipulateType;
      date?: dayjs.ConfigType;
      format?: TimeFormat;
      setTime?: setTimeType;
    },
  ) => {
    const { format, setTime, unit, date } = options;
    const formatType = () => {
      if (!format) {
        return 'YYYY-MM-DD HH:mm:ss';
      }
      switch (format?.toUpperCase()) {
        case 'MONTH': {
          return 'YYYY-MM';
        }
        case 'DAY': {
          return 'YYYY-MM-DD';
        }
        default: {
          return 'YYYY-MM-DD HH:mm:ss';
        }
      }
    };

    const Date = date ? dayjs(date) : dayjs();
    let result = null;
    if (0 === Number(value)) {
      result = Date;
    }
    result =
      type === 'add'
        ? Date.add(value, unit)
        : type === 'subtract'
        ? Date.subtract(value, unit)
        : Date;
    result =
      setTime === 'startAt'
        ? result.hour(0).minute(0).second(0)
        : setTime === 'endAt'
        ? result.hour(23).minute(59).second(59)
        : result;
    return format ? result.format(formatType()) : result;
  };

  static nowTimezone = (timezone: string) => {
    return dayjs().tz(timezone);
  };

  static nowUtcTimestamp = (): number => {
    return dayjs().utc().valueOf();
  };

  static today = {
    week: (() => {
      const todayWeekIndex = new Date().getDay();
      return week[todayWeekIndex];
    })(),
  };

  /**
   * 남은 기간 계산 함수 D 이후는 H M 병행 표기
   * @param t : 목표 날짜 (UTC)
   */
  static remainingDateForDHM = (t: string): string => {
    if (t === null || t === '') return '';

    const now = dayjs().utc();
    const date = dayjs(t).utc();

    let leftTimeDays = '';
    const day = date.diff(now, 'days');
    if (day >= 1) {
      leftTimeDays = `${day} D`;
    }

    let leftTimeHour = '';
    let leftTimeMin = '';
    const hour = dayjs.duration(date.diff(now)).hours();
    if (hour >= 1 && hour < 24) {
      leftTimeHour = ` ${hour} H`;
    }

    if (leftTimeDays === '') {
      const min = dayjs.duration(date.diff(now)).minutes();
      if (min >= 1 && min < 60) {
        leftTimeMin = ` ${min} M`;
      }
    }

    return `${leftTimeDays}${leftTimeHour}${leftTimeMin}`;
  };

  /**
   * 로컬시간 기준 남은 시간 계산 함수,  D 이후는 H M 병행 표기
   * create by 박현정 2023.1.4
   * @param t : 목표 날짜 (UTC)
   * TODO:로컬타임관련 함수 따로 분리하면 좋을듯
   */
  static remainingLocalDateForDHM = (t: string): string => {
    if (t === null || t === '') return '';

    const now = dayjs();
    const date = dayjs(convertFormat(t));

    let leftTimeDays = '';
    const day = date.diff(now, 'days');
    if (day >= 1) {
      leftTimeDays = `${day} D`;
    }

    let leftTimeHour = '';
    let leftTimeMin = '';
    const hour = dayjs.duration(date.diff(now)).hours();
    if (hour >= 1 && hour < 24) {
      leftTimeHour = ` ${hour} H`;
    }

    if (leftTimeDays === '') {
      const min = dayjs.duration(date.diff(now)).minutes();
      if (min >= 1 && min < 60) {
        leftTimeMin = ` ${min} M`;
      }
    }

    return `${leftTimeDays}${leftTimeHour}${leftTimeMin}`;
  };

  /**
   * 남은 기간 계산 함수 (day / hour / min 순으로 표기)
   * @param t : 목표 날짜
   */
  static remainingDateForLongDesc = (t: string) => {
    if (t === null || t === '') return '';

    const now = dayjs().utc();
    const date = dayjs(t).utc();

    const day = date.diff(now, 'days');
    if (day >= 1) {
      return `${day} ${Number(day) === 1 ? 'Day' : 'Days'}`;
    }

    const hour = dayjs.duration(date.diff(now)).hours();
    if (hour >= 1) {
      return `${hour} ${Number(hour) === 1 ? 'Hour' : 'Hours'}`;
    }

    const min = dayjs.duration(date.diff(now)).minutes();
    if (min >= 1) {
      return `${min} Min`;
    }
    return '1 Min';
  };

  /**
   * 로컬시간 기준 남은 기간 계산 함수 (day / hour / min 순으로 표기)
   * @param t : 목표 날짜
   */
  static remainingLocalDateForLongDesc = (t: string) => {
    if (t === null || t === '') return '';

    const now = dayjs().utc();
    const date = dayjs(convertFormat(t));

    const day = date.diff(now, 'days');
    if (day >= 1) {
      return `${day} ${Number(day) === 1 ? 'Day' : 'Days'}`;
    }

    const hour = dayjs.duration(date.diff(now)).hours();
    if (hour >= 1) {
      return `${hour} ${Number(hour) === 1 ? 'Hour' : 'Hours'}`;
    }

    const min = dayjs.duration(date.diff(now)).minutes();
    if (min >= 1) {
      return `${min} Min`;
    }
    return '1 Min';
  };
}

export { _days };

export type { ManipulateType };
