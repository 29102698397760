import { PaymentContext, PgType } from '@src/view/components/payment/context';
import { useCallback, useEffect } from 'react';
import { usePaymentData, useStore } from '@src/view/components/payment/data';
import AppManager from '@src/lib/app/AppManager';
import { CloseBtn } from '@src/view/common/closeBtn';
import { ModalInstance } from '@src/lib/CustomModal/ModalContainer';
import PayMethodItem from '@src/view/components/payment/view/payment/PayMethodItem';
import { PaymentWallRefillRetryPurchase } from '@src/view/components/payment/action/PaymentWallRefillRetryPurchase';
import { PaymentWindowModeType } from '@src/view/components/payment/data/_constants';
import cn from 'classnames';

const key = 'refillMiniPaymentModal';

export const openRefillMiniPaymentModal = (props: any) => {
  ModalInstance.getInstance().push({
    key,
    shouldCloseOnOverlayClick: true,
    component: <RefillMiniPaymentModal {...props} />,
  });
};

export const closeRefillMiniPaymentModal = () => {
  ModalInstance.getInstance().delete(key);
};

const RefillMiniPaymentModal = ({ token, orderId }: any) => {
  const isStoreApp = AppManager.getInstance().isStoreApp();
  const store = useStore();
  const { data: paymentContext } = usePaymentData();

  useEffect(() => {
    if (!paymentContext || !paymentContext.defaultProduct) return;
    store.setSelectItem(paymentContext.defaultProduct);
  }, [paymentContext]);

  const purchaseAction = useCallback(
    (pgItem: PgType.MethodItemType, context: PaymentContext) => {
      switch (pgItem.pg) {
        case 'paymentwall': {
          console.log('ttt', pgItem, context, store);
          PaymentWallRefillRetryPurchase.PaymentWallRefillRetryPurchase(
            context,
            pgItem.method,
            orderId,
          );
        }
        default: {
          console.warn('not found work', pgItem);
        }
      }
    },
    [store.selectItem],
  );

  return (
    <div className="bg-white rounded-[5px] text-[1rem] max-w-[450px] max-h-[76vh] w-[94vw] overflow-y-auto overflow-hidden scrollbar-hide">
      <div className="pt-[15px] w-full bg-white relative z-[1] rounded-[0] break-all">
        <CloseBtn
          imgPath="/images/alert/close/ico_close.png"
          className="absolute right-[4px] top-[4px] text-transparent z-[1] w-[35px] h-[35px] inline-block cursor-pointer"
        />
        {/* <WithPaymentHoc
          isStoreApp={isStoreApp}
          windowMode={PaymentWindowModeType.MINI}
          WrappedComponent={isStoreApp ? PaymentStoreAppMain : PaymentMain}
        /> */}
        <div className="p-[20px] text-black">
          {paymentContext?.pgGroups.refill.map((item, index) => {
            return (
              <>
                <div className="text-[18px] text-center mb-[20px] leading-[1.7rem]">
                  Select a new payment method to register
                </div>
                <div
                  key={`pg-item-${item.title}}`}
                  id="pgList"
                  className={cn(
                    'grid grid-rows-[1fr] mt-[10px] gap-[12px] mo:gap-[5px] mb-[20px] mo:grid-cols-2',
                    {
                      'grid-cols-2': item.pgList.length > 1,
                      'grid-cols-1': item.pgList.length === 1,
                    },
                  )}
                >
                  {item.pgList.map((c, index) => {
                    return (
                      <PayMethodItem.MiniRefillPay
                        key={`${c.pg}-${c.method || ''}-${c.order}`}
                        pgItem={c}
                        windowMode={PaymentWindowModeType.LARGE}
                        onClick={e => {
                          console.log(c);
                          purchaseAction(c, paymentContext);
                          // onClick(e, c);
                        }}
                      />
                    );
                  })}
                </div>
              </>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default RefillMiniPaymentModal;
