import { useEffect, useRef, useState } from 'react';
import { Event } from '@src/lib/commonLib/gtm/Gtm';
import { GlobalGtm } from '@src/lib/commonLib/gtm/global';
import { TopcoPartner } from '@src/lib/partner';
import { openLoginErrorNotificationModal } from '@src/view/modal/loginErrorNotification/LoginErrorNotificationModal';

interface Props {
  src: string;
  alt: string;
  isLoginError?: boolean;
}
const GoogleLoginButton = (props: Props) => {
  const googleLoginRef = useRef(null);
  const { src, alt, isLoginError = false } = props;
  const [hidden, setHidden] = useState(false);

  useEffect(() => {
    if (typeof window !== undefined) {
      const { google }: any = window;
      if (!google) {
        setHidden(true);
        return;
      }

      google.accounts.id.renderButton(googleLoginRef.current, {
        type: 'icon',
        theme: 'outline',
        size: 'large',
        shape: 'circle',
        width: 42,
        locale: 'en_US',
        click_listener: () => {
          // GTM - 가입 버튼 클릭
          try {
            GlobalGtm.getInstance().setter({
              event: Event.JOIN_BUTTON_CLICK,
              pCode: TopcoPartner.getter(),
            });
          } catch {
            console.error('gtm');
          }
        },
      });
    }
  }, []);

  if (hidden) return null;

  // google에서 제공하는 버튼은 투명하게 처리 후, z-index로 위치를 조정(기존 클릭 이벤트 유지)하여 , 우리가 보여주고 싶은 이미지 노출.
  return (
    <>
      <div
        className="absolute z-[9] top-0 w-full h-full"
        onClick={(e: any) => {
          if (!isLoginError) return;
          openLoginErrorNotificationModal({ provider: 'google' });
        }}
      >
        <img src={src} alt={alt} />
      </div>
      {!isLoginError && (
        <div
          ref={googleLoginRef}
          className="relative opacity-0 z-[10] w-full h-full"
        />
      )}
    </>
  );
};

export default GoogleLoginButton;
