import {
  OAuthProvider,
  getAuth,
  signInWithPopup,
  signInWithRedirect,
} from '@firebase/auth';
import { UserDefault, UserSession } from '@src/lib';
import {
  closePopupBlockGuideModal,
  openPopupBlockGuideModal,
} from '@src/view/modal/popupBlockGuideModal./PopupBlockGuideModal';

import { ApiLogin } from '@src/lib/commonLib/api/client/V1';
import AppManager from '@src/lib/app/AppManager';
import Cookies from 'js-cookie';
import { FirebaseError } from '@firebase/app';
import { Event } from '@src/lib/commonLib/gtm/Gtm';
import { GlobalGtm } from '@src/lib/commonLib/gtm/global';
import { Marketing } from '@src/lib/utils/Marketing';
import { SignUpBenefitMode } from '@src/view/modal/signupBenefitModal/_constants';
import { TopcoPartner } from '@src/lib/partner';
import { VisitorUtils } from '@src/lib/utils/VisitorUtils';
import _ from 'lodash';
import dayjs from 'dayjs';

export class GoogleTopcoAuthReqeust implements TopcoAuthRequest {
  image: string;
  providerAccountId: string;
  provider: string;
  email: string;
  isAlreadyMature: number;

  constructor(data: any) {
    this.image = data['image'] || '';
    this.providerAccountId = data['providerAccountId'] || '';
    this.provider = data['provider'] || '';
    this.email = data['email'] || '';
    this.isAlreadyMature = data['isAlreadyMature'] || 0;
  }

  public isValidationForm() {
    return this.provider !== '' && this.providerAccountId !== '';
  }
}

export interface TopcoAuthRequest {
  image: string;
  providerAccountId: string;
  provider: string;
  email: string;
  isAlreadyMature: number;
}

export interface AppleUserInfo {
  uid: string;
}

export namespace AuthController {
  /**
   * 소셜에서 획득한 credential을 backend api로 전달한 후 로그인 처리된 결과값을 전달 받음.
   * @param credential
   * @returns
   */
  export const ownToken = async (credential: TopcoAuthRequest) => {
    try {
      return new ApiLogin(UserDefault.getUserSession())
        .snsLoginProc(credential)
        .then(res => res.data)
        .then(res => res.data);
    } catch (e) {
      throw new Error('sns login error');
    }
  };
  /**
   * twitter login
   * @param hostName
   */
  export const twitterLogin = async (hostName: string) => {
    new ApiLogin(UserDefault.getUserSession())
      .getTwitterOauthToken(hostName)
      .then((res: any) => {
        const { data } = res.data;
        Cookies.remove('twitterToken');
        Cookies.set('twitterToken', data.twitterToken, {
          expires: 1,
        });

        if (_.has(data, 'twitterOauthUrl')) {
          window.open(data.twitterOauthUrl, '_self');
        } else {
          throw new Error('api login error');
        }
      })
      .catch(err => {
        console.warn(err);
      });
  };

  /**
   * apple login
   * @param callback
   * @returns
   */
  export const appleLoginIn = (callback: (uid: any) => void) => {
    const appManager = AppManager.getInstance();

    if (appManager.isIosApp() && appManager.isOriginApp()) {
      const rUrl = encodeURIComponent('/appSnsCallback?provider=apple');
      appManager.action.showBrowser(rUrl);
      callback(null);
      return;
    }
    const auth = getAuth();

    if (!auth) return;
    const provider = new OAuthProvider('apple.com');
    provider.addScope('email');

    if (appManager.isAndroidApp()) {
      signInWithRedirect(auth, provider);
      return;
    }

    signInWithPopup(auth, provider)
      .then(result => {
        const { user } = result;
        const { uid } = user;
        callback(uid);
      })
      .catch((err: FirebaseError) => {
        console.warn('##################', err.message, err.code);
        if (err.code === 'auth/popup-blocked') {
          openPopupBlockGuideModal({
            onClose() {
              closePopupBlockGuideModal();
              callback(null);
            },
          });
          return;
        }

        callback(null);
      });
  };

  /**
   * googleLogin
   * @param credential
   * @param callback
   */
  export const googleLogin = async (
    credential: string,
    callback: () => void,
  ) => {
    const JWTparse = (token: string) => {
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const payload = decodeURIComponent(
        atob(base64)
          .split('')
          .map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join(''),
      );
      return JSON.parse(payload);
    };
    const cred = JWTparse(credential);
    const user = await ownToken({
      image: cred.name,
      providerAccountId: cred.sub,
      provider: 'google.com',
      email: cred.email,
      isAlreadyMature: UserDefault.preAuthCheckBox.getter() ? 1 : 0, // 로그인,회원가입 체크박스 상태
    });

    try {
      if (user.sign === 1) {
        // 가입 혜택 정보 레이어 관련
        UserDefault.signupBenefitModalFlag.setter(SignUpBenefitMode.WELCOME);

        // GTM - 가입완료 (sns - google)
        GlobalGtm.getInstance().setter({
          event: Event.JOIN,
          pCode: TopcoPartner.getter(),
          params: {
            user: {
              loginId: user.loginId,
              userId: user.userId ?? 0,
              method: TopcoPartner.getter(),
            },
          },
          s2: UserDefault.dv360.getter(),
        });

        // assist 호출
        await Marketing.Assist.sendApi();
        //trafficJunky 매체 가입완료
        await Marketing.TrafficJunky.sendSignupApi(user.userId ?? 0);
      } else {
        // GTM - 로그인 (sns - google)
        if (!Cookies.get('login')) {
          const today = dayjs().tz('UTC').format('YYYY-MM-DDT23:59:59Z');
          Cookies.set('login', '1', { expires: new Date(today) });

          GlobalGtm.getInstance().setter({
            event: Event.LOGIN,
            pCode: TopcoPartner.getter(),
          });
        }
      }
    } catch {
      console.warn('gtm');
    }
    UserSession.setUserInfo({
      ...user,
      provider: 'google',
    });
    UserSession.setRevokeToken(user.revokeToken ?? ''); // 자동로그인을 위한 revoke token추가 : google
    callback();
  };
}
