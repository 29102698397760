import { AxiosInstanceType, TopcoAxiosInstance } from "../topcoAxiosInstance";

interface DownloadCountType {
  appGroup: number;
}

/**
 * 앱 관련 API
 */
export default class ApiAppInfo extends TopcoAxiosInstance {
  private UPDATE_APP_DOWNLOAD_COUNT = "/app/setDownloadCount";

  constructor(initValue: AxiosInstanceType) {
    super(initValue);
  }

  /**
   * 앱 설치 통계 API
   * @param appGroup 1:ios자체앱 2:android자체앱
   */
  public setDownloadCount = (appGroup: number) => {
    const data: DownloadCountType = {
      appGroup,
    };

    return this.requst<DownloadCountType>({
      method: "post",
      url: this.UPDATE_APP_DOWNLOAD_COUNT,
      data,
    });
  };
}
