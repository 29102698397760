import { AxiosInstanceType, TopcoAxiosInstance } from "../topcoAxiosInstance";

export interface GetNoticeListType {
  page: number;
  limit: number;
}

export interface SetLogNoticeType {
  noticeId: number;
}

export default class ApiNotice extends TopcoAxiosInstance {
  private GET_NOTICE_LIST = "/api/v1/notice/list";
  private SET_LOG_NOTICE = "/api/v1/notice/setLogNotice";

  constructor(initValue: AxiosInstanceType) {
    super(initValue);
  }

  /**
   * 공지사항 리스트
   * @param request
   * @returns
   */
  public getNoticeList = (request: GetNoticeListType) => {
    return this.requst<GetNoticeListType>({
      method: "post",
      url: this.GET_NOTICE_LIST,
      data: request,
    });
  };

  /**
   * 공지사항 클릭 시 쌓는 로그
   * - 유저당 1번, 각 공지사항 따로 카운트
   * @param request
   * @returns
   */
  public setLogNotice = (request: SetLogNoticeType) => {
    return this.requst<any>({
      method: "post",
      url: this.SET_LOG_NOTICE,
      data: request,
    });
  };
}
