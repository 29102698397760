import _ from 'lodash';
import { Gtm, GtmSetType } from './Gtm';

/**
 * global 에서 사용할 Gtm 클래스 (싱글톤)
 */
export class GlobalGtm extends Gtm {
  private static ID = 'GTM-TBNF2HF';
  private static instance: GlobalGtm;
  private fb_token =
    'EAARYzr8GXcgBAF3ZC3zoez7KiZBxXUpSiaKCzldndE7EB6A0gxKazmkIBiIDx3EgzsKLJibc8SQF3QHVoP0R9tZB2PDnVC4ZC3ocZBlDIOVVTSDzRygdfH8DYlxrVcKD5uiB8Ni4VaZBtPi5zmCFHlesBizgoRHZB7hZBBv8FY7kM4FXGIY4Vl4J';
  private fb_id = 324049159385296;
  private isStop = true;

  constructor() {
    super(GlobalGtm.ID);
  }

  public static getInstance(): GlobalGtm {
    if (!this.instance) {
      this.instance = new GlobalGtm();
    }
    return this.instance;
  }

  /**
   * Production 환경이면 isStop = false로 설정
   * @param env GOOGLE_TAG_MANAGER 환경변수
   */
  public init(env: string): void {
    if (env === 'true') this.isStop = false;
  }

  /**
   * dataLayer 전송
   * @param data event, pCode, params, s2
   */
  public setter(data: GtmSetType): void {
    if (this.isStop) return;

    const { event, s2 } = data;

    this.sendToGtm(data);
    this.sendToDv360(event, s2);
  }
}
