import { AxiosInstanceType, TopcoAxiosInstance } from "../topcoAxiosInstance";

/**
 * App과 연동되는 API 리스트
 */
export default class ApiGlobal extends TopcoAxiosInstance {
  private GLOBAL_USER = "/api/v2/global/user";

  constructor(initValue: AxiosInstanceType) {
    super(initValue);
  }

  public globalUser = (token: string) => {
    return this.requst({
      method: "post",
      url: this.GLOBAL_USER,
    });
  };
}
